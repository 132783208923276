import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../utils/IP'; 
import {
  Container, Box, Typography, TextField, MenuItem, FormControl,
  InputLabel, Select, FormControlLabel, Checkbox, Button, IconButton,
  CircularProgress, ImageList, ImageListItem, Modal, Fade,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { fetchRooms } from '../../features/propertySlice';
import { useSwipeable } from 'react-swipeable';
import colors from '../../styles/colors';
import EditIcon from '@mui/icons-material/Edit';
import { parseISO } from 'date-fns';




const RoomProfile = () => {
  const { roomId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { properties, rooms, loading } = useSelector(state => state.property);

  const [roomData, setRoomData] = useState({
    name: '',
    unitType: '',
    floor: '',
    sharingType: '',
    rent: '',
    area: '',
    isAvailable: false,
    remarks: '',
    facilities: [],
    roomTypes: [],
    lastAddedReading: '',
    readingDate: null,
    property: '',
    status: 'Active',
    maxOcc: 1,
    currentOcc: 0,
    vacantOcc: 1,
    tenants: []
  });
  
  const [mediaState, setMediaState] = useState([]);
  const [newMedia, setNewMedia] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [localLoading, setLocalLoading] = useState(true);
  const [openPreview, setOpenPreview] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [mediaToDelete, setMediaToDelete] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (rooms && roomId) {
      const fetchedRoom = rooms.find(r => r._id === roomId);
      if (fetchedRoom) {
        setRoomData(prevData => ({
          ...prevData,
          ...fetchedRoom,
          name: fetchedRoom.name || '',
          rent: fetchedRoom.rent?.toString() || '',
          area: fetchedRoom.area?.toString() || '',
          lastAddedReading: fetchedRoom.lastAddedReading?.toString() || '',
          readingDate: fetchedRoom.readingDate ? new Date(fetchedRoom.readingDate) : null,
          facilities: fetchedRoom.facilities || [],
          roomTypes: fetchedRoom.roomTypes || [],
          remarks: fetchedRoom.remarks || '',
          status: fetchedRoom.status || 'Active',
          maxOcc: fetchedRoom.maxOcc || 1,
          currentOcc: fetchedRoom.currentOcc || 0,
          vacantOcc: fetchedRoom.vacantOcc || 1,
          tenants: fetchedRoom.tenants || []
        }));
        setMediaState(fetchedRoom.media || []);
        setLocalLoading(false);
      } else {
        setLocalLoading(false);
      }
    } else if (!loading) {
      setLocalLoading(false);
    }
  }, [rooms, roomId, loading]);

  const handleChange = (e) => {
    if (!isEditable) return;
    const { name, value, checked, type } = e.target;
    if (type === 'checkbox') {
      setRoomData(prevData => ({ ...prevData, [name]: checked }));
    } else if (name === 'sharingType') {
      const sharingTypeValue = parseInt(value, 10);
      setRoomData(prevData => ({
        ...prevData,
        [name]: value,
        maxOcc: sharingTypeValue,
        vacantOcc: sharingTypeValue - prevData.currentOcc
      }));
    } else {
      setRoomData(prevData => ({ ...prevData, [name]: value }));
    }
  };

  const handleDateChange = (date) => {
    if (!isEditable) return;
    setRoomData(prevData => ({ ...prevData, readingDate: date }));
  };

  const handleFacilitiesChange = (e) => {
    if (!isEditable) return;
    const { value, checked } = e.target;
    setRoomData(prevData => ({
      ...prevData,
      facilities: checked
        ? [...prevData.facilities, value]
        : prevData.facilities.filter(facility => facility !== value)
    }));
  };

  const handleRoomTypesChange = (e) => {
    if (!isEditable) return;
    const { value, checked } = e.target;
    setRoomData(prevData => ({
      ...prevData,
      roomTypes: checked
        ? [...prevData.roomTypes, value]
        : prevData.roomTypes.filter(type => type !== value)
    }));
  };

  const handleMediaChange = (e) => {
    if (!isEditable) return;
    const files = Array.from(e.target.files);
    setNewMedia(prevMedia => [...prevMedia, ...files]);
  };

  const handleOpenDialog = (type, message, media = null) => {
    setDialogType(type);
    setDialogMessage(message);
    setMediaToDelete(media);
    setDialogOpen(true);
  };

  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const handleDeleteMedia = (mediaItem) => {
    handleOpenDialog(
      'deleteMedia',
      'Are you sure you want to delete this media? This action cannot be undone.',
      mediaItem
    );
  };

  const handleConfirmedAction = async () => {
    if (dialogType === 'deleteMedia' && mediaToDelete) {
      setMediaState(prevMedia => prevMedia.filter(item => item._id !== mediaToDelete._id));
      handleCloseDialog();
    } else if (dialogType === 'saveChanges') {
      handleCloseDialog();
      setIsUpdating(true);
      try {
        const token = localStorage.getItem('token');
        const formData = new FormData();

        const updatedRoomData = {
          ...roomData,
          maxOcc: parseInt(roomData.sharingType, 10),
          vacantOcc: parseInt(roomData.sharingType, 10) - roomData.currentOcc,
          readingDate: roomData.readingDate ? roomData.readingDate.toISOString() : null,
        };

        formData.append('roomData', JSON.stringify(updatedRoomData));
        formData.append('existingMedia', JSON.stringify(mediaState));

        newMedia.forEach((file) => {
          formData.append('media', file);
        });

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': token,
          },
        };
        
        const response = await axios.put(`${BASE_URL}/rooms/${roomId}`, formData, config);
       
        if (response.data) {
          setRoomData(prevData => ({
            ...prevData,
            ...response.data,
            readingDate: response.data.readingDate ? new Date(response.data.readingDate) : null,
          }));
          setMediaState(response.data.media || []);
          setNewMedia([]);
          setIsEditable(false);
          setDialogType('success');
          setDialogMessage('Room details updated successfully!');
          setDialogOpen(true);
        }
      } catch (error) {
        console.error('Error updating room', error);
        setDialogType('error');
        setDialogMessage('Failed to update room details. Please try again.');
        setDialogOpen(true);
      } finally {
        setIsUpdating(false);
      }
    }
  };

  useEffect(() => {
    // Auto-close success and error dialogs after 3 seconds
    if (dialogOpen && (dialogType === 'success' || dialogType === 'error')) {
      const timer = setTimeout(() => {
        handleCloseDialog();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [dialogOpen, dialogType, handleCloseDialog]);

  const handlePrevMedia = useCallback(() => {
    setCurrentMediaIndex((prevIndex) => {
      const mediaLength = roomData?.media?.length ?? 0;
      return prevIndex > 0 ? prevIndex - 1 : mediaLength - 1;
    });
  }, [roomData]);

  const handleNextMedia = useCallback(() => {
    setCurrentMediaIndex((prevIndex) => {
      const mediaLength = roomData?.media?.length ?? 0;
      return prevIndex < mediaLength - 1 ? prevIndex + 1 : 0;
    });
  }, [roomData]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNextMedia,
    onSwipedRight: handlePrevMedia,
  });

  const handleBackClick = () => {
    navigate(-1);
  };

  const renderMediaPreview = () => {
    if (!roomData || !roomData.media || roomData.media.length === 0) {
      return <Typography>No media available</Typography>;
    }

    const currentMedia = roomData.media[currentMediaIndex];
    if (!currentMedia) {
      return <Typography>Media not found</Typography>;
    }

    return currentMedia.type.startsWith('image') ? (
      <img
        src={`${BASE_URL}${currentMedia.path}`}
        alt={currentMedia.name || 'Room media'}
        style={{ width: '100%', height: 'auto', maxHeight: '80vh', objectFit: 'contain' }}
      />
    ) : (
      <video
        src={`${BASE_URL}${currentMedia.path}`}
        controls
        style={{ width: '100%', height: 'auto', maxHeight: '80vh' }}
      />
    );
  };

  const handleOpenPreview = (index) => {
    setCurrentMediaIndex(index);
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  if (localLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!roomData) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography>Room not found</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="sm" disableGutters>
      <Box
        sx={{
          bgcolor: colors.secondary, // Primary color
          pb: 2,
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
          mx: 0,
          px: 2,
          color: colors.primary,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', pt: 2 }}>
          <IconButton onClick={handleBackClick} sx={{ color: colors.primary }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ fontSize: '1.2rem', textAlign: 'left', flexGrow: 1 }}>
            Room Profile
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsEditable(!isEditable)}
            sx={{bgcolor:colors.teal,color:colors.secondary,borderRadius:'8px'}}
          >
            <EditIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />
            {isEditable ? 'Cancel' : 'Edit'}
          </Button>
        </Box>
      </Box>
      <form onSubmit={(e) => { e.preventDefault(); handleOpenDialog('saveChanges', 'Are you sure you want to save these changes to the room details?'); }}>
        <Box sx={{ mb: 3, mt: 2, mx: 1, bgcolor: '#fff', p: 2, borderRadius: '8px' }}>
          <TextField fullWidth label="Room Name" name="name" value={roomData.name} onChange={handleChange} disabled={!isEditable} sx={{ mb: 2 }} />
          <TextField fullWidth label="Unit Type" name="unitType" value={roomData.unitType} onChange={handleChange} select disabled={!isEditable} sx={{ mb: 2 }}>
            <MenuItem value="1RK">1 RK</MenuItem>
            <MenuItem value="1BHK">1 BHK</MenuItem>
            <MenuItem value="2BHK">2 BHK</MenuItem>
            <MenuItem value="3BHK">3 BHK</MenuItem>
          </TextField>
          <TextField fullWidth label="Floor" name="floor" value={roomData.floor} onChange={handleChange} select disabled={!isEditable} sx={{ mb: 2 }}>
            <MenuItem value="Basement">Basement</MenuItem>
            <MenuItem value="Ground Floor">Ground Floor</MenuItem>
            <MenuItem value="1st Floor">1st Floor</MenuItem>
            <MenuItem value="2nd Floor">2nd Floor</MenuItem>
            <MenuItem value="3rd Floor">3rd Floor</MenuItem>
            <MenuItem value="4th Floor">4th Floor</MenuItem>
          </TextField>
          <TextField 
            fullWidth 
            label="Sharing Type" 
            name="sharingType" 
            value={roomData.sharingType} 
            onChange={handleChange}
            select 
            disabled={!isEditable}
            sx={{ mb: 2 }}
          >
            <MenuItem value="1">Single Sharing (1 Person)</MenuItem>
            <MenuItem value="2">Double Sharing (2 People)</MenuItem>
            <MenuItem value="3">Triple Sharing (3 People)</MenuItem>
            <MenuItem value="4">Quadruple Sharing (4 People)</MenuItem>
          </TextField>
          <TextField fullWidth label="Rent" name="rent" value={roomData.rent} onChange={handleChange} type="number" disabled={!isEditable} InputProps={{ startAdornment: <Typography>₹</Typography> }} sx={{ mb: 2 }} />
          <TextField fullWidth label="Area (Sqft)" name="area" value={roomData.area} onChange={handleChange} type="number" disabled={!isEditable} sx={{ mb: 2 }} />
          <FormControlLabel
            control={
              <Checkbox
                checked={roomData.isAvailable}
                onChange={handleChange}
                name="isAvailable"
                disabled={!isEditable}
              />
            }
            label="Is this Room available to Rent?"
          />
          <TextField fullWidth label="Remarks" name="remarks" value={roomData.remarks} onChange={handleChange} disabled={!isEditable} sx={{ mb: 2 }} />
          <TextField 
            fullWidth 
            label="Status" 
            name="status" 
            value={roomData.status} 
            onChange={handleChange} 
            select 
            disabled={!isEditable}
            sx={{ mb: 2 }}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </TextField>

          <TextField fullWidth label="Remarks" name="remarks" value={roomData.remarks || ''} onChange={handleChange} sx={{ mb: 2 }} InputProps={{ readOnly: !isEditable }} />
          <Box sx={{ mb: 3, mx: 1, bgcolor: '#fff', p: 0, borderRadius: '8px' }}>
            <Typography component="legend">Room Facilities</Typography>
            {['AC', 'Table', 'TV', 'Washroom', 'Balcony', 'Fridge', 'Almirah', 'Chair', 'Food', 'Microwave', 'Geyser', 'Laundry', 'CCTV', 'Toilet', 'Cooler'].map((facility) => (
              <FormControlLabel
                key={facility}
                control={
                  <Checkbox
                    checked={roomData.facilities?.includes(facility) || false} // Optional chaining to avoid undefined error
                    onChange={handleFacilitiesChange}
                    value={facility}
                    disabled={!isEditable}
                  />
                }
                label={facility}
              />
            ))}
          </Box>

          <Box sx={{ mb: 3, mx: 1, bgcolor: '#fff', p: 0, borderRadius: '8px' }}>
            <Typography component="legend">Choose type of Room</Typography>
            {['Corner Room', 'Large Room', 'Ventilation', 'Furnished', 'Unfurnished', 'Semi Furnished', 'Short-Term', 'Long-Term', 'Premium Room', 'Basic Room', 'Double Sharing'].map((type) => (
              <FormControlLabel
                key={type}
                control={
                  <Checkbox
                    value={type}
                    onChange={handleRoomTypesChange}
                    checked={roomData.roomTypes?.includes(type) || false} // Optional chaining to avoid undefined error
                    disabled={!isEditable}
                  />
                }
                label={type}
              />
            ))}
          </Box>

          <Box sx={{ mb: 3, mx: 1, bgcolor: '#fff', p: 0, borderRadius: '8px' }}>
            <Typography component="legend" sx={{ mb: 2 }}>Electricity Reading</Typography>
            <TextField fullWidth label="Last Added Reading" name="lastAddedReading" value={roomData.lastAddedReading || ''} onChange={handleChange} sx={{ mb: 2 }} InputProps={{ readOnly: !isEditable }} />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Last Added Reading"
                value={roomData.readingDate}
                onChange={handleDateChange}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    sx: { mb: 2 },
                    InputProps: { readOnly: !isEditable }
                  },
                }}
              />
          </LocalizationProvider>
          </Box>

          <Box sx={{ mb: 3, mt: 2, mx: 1, bgcolor: '#fff', p: 0, borderRadius: '8px' }}>
          <Typography component="legend" sx={{paddingBottom:1}}>Room Media</Typography>
          <ImageList sx={{ width: '100%', height: 'auto' }} cols={3} rowHeight={100}>
            {mediaState.map((item, index) => (
              <ImageListItem key={index}>
                {item.type.startsWith('image') ? (
                  <img
                    src={`${BASE_URL}${item.path}`}
                    alt={item.name}
                    loading="lazy"
                    style={{ cursor: 'pointer', objectFit: 'cover', height: '100%', width: '100%' }}
                    onClick={() => handleOpenPreview(index)}
                  />
                ) : (
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: 'grey.300',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleOpenPreview(index)}
                  >
                    <Typography>Video</Typography>
                  </Box>
                )}
                {isEditable && (
                  <IconButton
                    sx={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      color: 'white',
                    }}
                    onClick={() => handleDeleteMedia(item)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </ImageListItem>
            ))}
            {newMedia.map((file, index) => (
              <ImageListItem key={`new-${index}`}>
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  loading="lazy"
                  style={{ cursor: 'pointer', objectFit: 'cover', height: '100%', width: '100%' }}
                />
              </ImageListItem>
            ))}
          </ImageList>
          {isEditable && (
            <Button variant="outlined" component="label" sx={{ mt: 2 }}>
              Upload New Media
              <input type="file" hidden multiple onChange={handleMediaChange} accept="image/*, video/*" />
            </Button>
          )}
        </Box>
        </Box>

        {isEditable && (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            sx={{ mt: 1, mb: 2 }}
          >
            Save Changes
          </Button>
        )}
      </form>

      {isUpdating && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress />
        </Box>
      )}

      <Modal
        open={openPreview}
        onClose={handleClosePreview}
        closeAfterTransition
      >
        <Fade in={openPreview}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }} {...swipeHandlers}>
            <IconButton
              sx={{ position: 'absolute', right: 8, top: 8 }}
              onClick={handleClosePreview}
            >
              <CloseIcon />
            </IconButton>
            {renderMediaPreview()}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <IconButton onClick={handlePrevMedia}>
                <NavigateBeforeIcon />
              </IconButton>
              <IconButton onClick={handleNextMedia}>
                <NavigateNextIcon />
              </IconButton>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogType === 'deleteMedia' ? "Confirm Deletion" :
           dialogType === 'saveChanges' ? "Confirm Changes" :
           dialogType === 'success' ? "Success" : "Error"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {(dialogType === 'deleteMedia' || dialogType === 'saveChanges') ? (
            <>
              <Button onClick={handleCloseDialog}>Cancel</Button>
              <Button onClick={handleConfirmedAction} autoFocus>
                Confirm
              </Button>
            </>
          ) : (
            <Button onClick={handleCloseDialog} autoFocus>
              OK
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default RoomProfile;