import React, { useState, useMemo } from 'react';
import { Container, Box, Typography, Card, IconButton, InputBase, Paper, Grid, Link } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import colors from '../../styles/colors';

const PastTenantList = () => {
  const { tenants, rooms } = useSelector(state => state.property);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const filteredPastTenants = useMemo(() => {
    return tenants.filter((tenantOccupancy) => {
      if (!tenantOccupancy || !tenantOccupancy.tenant || !tenantOccupancy.tenant.name) {
        return false;
      }
      const isExited = tenantOccupancy.status === 'Exited';
      const includesSearchTerm = tenantOccupancy.tenant.name.toLowerCase().includes(searchTerm.toLowerCase());
      return isExited && includesSearchTerm;
    });
  }, [tenants, searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleBackClick = () => {
    navigate('/tenants');
  };

  return (
    <Container sx={{ pb: 18 }}>
      <Box
        sx={{
          bgcolor: colors.secondary,
          pb: 2,
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
          mx: -2,
          px: 3,
          position: 'sticky',
          top: 0,
          zIndex: 1000,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', pt: 2, color: colors.primary }}>
          <IconButton onClick={handleBackClick} sx={{ color: colors.primary }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ fontSize: '1.2rem', textAlign: 'left', flexGrow: 1 }}>
            Past Tenants
          </Typography>
        </Box>
      </Box>

      <Paper sx={{ mt: 2, mb: 3, display: 'flex', alignItems: 'center', p: 1, borderRadius: '15px', border: '1px solid #ccc', boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)' }}>
        <SearchIcon sx={{ mr: 1, color: colors.primary }} />
        <InputBase
          placeholder="Search Past Tenants..."
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </Paper>

      {filteredPastTenants.length > 0 ? (
        filteredPastTenants.map((tenantOccupancy) => {
          const tenant = tenantOccupancy.tenant;
          const room = tenantOccupancy.room;

          return (
            <Card key={tenantOccupancy._id} 
              sx={{ mt: 2, px: 2, py: 1, bgcolor: '#fff',
              borderRadius: '10px', boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)',
              cursor: 'pointer'
              }}
              onClick={() => navigate(`/tenant-profile/${tenant._id}`)}
            >
              <Grid container spacing={0} sx={{padding:1 }}>
                <Grid item xs={2.5} sx={{alignContent:'center'}}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 50,
                      height: 50,
                      minWidth: 50,
                      minHeight: 50,
                      border:0,
                      borderColor:colors.primary,
                      bgcolor: colors.mint,
                      borderRadius: '50%',
                      color: colors.primary,
                      fontSize: '1.2rem',
                      mr: 0,
                    }}
                  >
                    <Typography variant="h6" sx={{ opacity: '50%',textTransform: 'capitalize' , fontWeight:'bold'}}>
                      {tenant.name ? tenant.name.charAt(0) : '?'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={9.5} sx={{}}>
                  <Box>
                    <Typography variant="body1" noWrap sx={{ textTransform: 'capitalize',fontWeight: 600}}>
                      {tenant.name || 'Unknown'}
                    </Typography>
                    <Typography variant="body2">{room.name}</Typography>
                    <Typography variant="body2">{room.floor}</Typography>
                    <Typography variant="body2">Exit Date: {new Date(tenantOccupancy.exitDate).toLocaleDateString()}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          );
        })
      ) : (
        <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>No past tenants found</Typography>
      )}
    </Container>
  );
};

export default PastTenantList;