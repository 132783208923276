import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../utils/IP'; 
import { 
  Container, Box, Typography, TextField, MenuItem, FormControl,
  InputLabel, Select, FormControlLabel, Checkbox, Button, IconButton,
  List, ListItem, ListItemText, ListItemSecondaryAction,InputAdornment
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { fetchRooms } from '../../features/propertySlice';
import colors from '../../styles/colors';


const AddRoom = () => {
  const [roomData, setRoomData] = useState({
    name: '',
    unitType: '',
    floor: '',
    sharingType: '',
    rent: '',
    area: '',
    isAvailable: true,
    remarks: '',
    facilities: [],
    roomTypes: [],
    lastAddedReading: '',
    readingDate: null,
    property: '',
    status: 'Active',
    maxOcc: 1,
    currentOcc: 0,
    vacantOcc: 1,
  });

  const [media, setMedia] = useState([]);
  const [error, setError] = useState('');
  const [isRentFocused, setIsRentFocused] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { properties, selectedProperty } = useSelector(state => state.property);

  useEffect(() => {
    if (selectedProperty) {
      setRoomData(prevData => ({ ...prevData, property: selectedProperty._id }));
    }
  }, [selectedProperty]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'isAvailable') {
      setRoomData({ ...roomData, [name]: checked });
    } else if (name === 'sharingType') {
      const sharingTypeValue = parseInt(value, 10);
      setRoomData({
        ...roomData,
        [name]: value,
        maxOcc: sharingTypeValue,
        vacantOcc: sharingTypeValue,
        currentOcc: 0,
      });
    } else {
      setRoomData({ ...roomData, [name]: value });
    }
  };

  const handleDateChange = (date) => {
    setRoomData({ ...roomData, readingDate: date });
  };

  const handleFacilitiesChange = (e) => {
    const { value, checked } = e.target;
    const updatedFacilities = checked
      ? [...roomData.facilities, value]
      : roomData.facilities.filter((facility) => facility !== value);
    setRoomData({ ...roomData, facilities: updatedFacilities });
  };

  const handleRoomTypesChange = (e) => {
    const { value, checked } = e.target;
    const updatedRoomTypes = checked
      ? [...roomData.roomTypes, value]
      : roomData.roomTypes.filter((type) => type !== value);
    setRoomData({ ...roomData, roomTypes: updatedRoomTypes });
  };

  const handleMediaChange = (e) => {
    const files = Array.from(e.target.files);
    setMedia([...media, ...files]);
  };

  const removeMedia = (index) => {
    const updatedMedia = [...media];
    updatedMedia.splice(index, 1);
    setMedia(updatedMedia);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();
  
      // Ensure maxOcc, currentOcc, and vacantOcc are set based on sharingType
      const sharingTypeValue = parseInt(roomData.sharingType, 10);
      const updatedRoomData = {
        ...roomData,
        maxOcc: sharingTypeValue,
        currentOcc: 0,
        vacantOcc: sharingTypeValue
      };
  
      // Append the stringified room data
      formData.append('roomData', JSON.stringify(updatedRoomData));
  
      // Append media files
      media.forEach((file, index) => {
        formData.append('media', file);
      });
  
      console.log('Data being sent:', updatedRoomData);
  
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': token,
        },
      };
      
      const response = await axios.post(`${BASE_URL}/rooms`, formData, config);
      console.log('Server response:', response.data);
      
      if (selectedProperty) {
        dispatch(fetchRooms(selectedProperty._id));
      }
      navigate('/rooms');
    } catch (error) {
      console.error('Error saving room:', error.response?.data || error.message);
      setError('Error adding room: ' + (error.response?.data?.message || error.message));
    }
  };
  
  const handleBackClick = () => {
    navigate(-1); // Go back to the previous screen
  };

  return (
    <Container maxWidth="sm" disableGutters>
      <Box
        sx={{
          bgcolor: colors.secondary, // Primary color
          pb: 2,
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
          mx: 0,
          px: 2,
          color: colors.primary,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', pt: 2 }}>
          <IconButton onClick={handleBackClick} sx={{ color: colors.primary }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ fontSize: '1.2rem', textAlign: 'left', flexGrow: 1 }}>
            Add Room
          </Typography>
          <Typography variant="h6" sx={{ fontSize: '1.2rem', textAlign: 'right' }}>
            {selectedProperty ? selectedProperty.name : ''}
          </Typography>
        </Box>
        
      </Box>
      <form onSubmit={handleSubmit}>
        <Box sx={{ mb: 3, mt: 2, mx: 1, bgcolor: '#fff', p: 2, borderRadius: '8px' }}>
          <Typography variant="h6" sx={{paddingBottom:1}} >Room Renting Details</Typography>
          <TextField fullWidth label="Room Name" name="name" value={roomData.name} onChange={handleChange} sx={{ mb: 2 }} required/>
          <TextField fullWidth label="Unit Type" name="unitType" value={roomData.unitType} onChange={handleChange} select sx={{ mb: 2 }} required>
            <MenuItem value="1RK">1 RK</MenuItem>
            <MenuItem value="1BHK">1 BHK</MenuItem>
            <MenuItem value="2BHK">2 BHK</MenuItem>
            <MenuItem value="3BHK">3 BHK</MenuItem>
          </TextField>
          <TextField fullWidth label="Floor" name="floor" value={roomData.floor} onChange={handleChange} select sx={{ mb: 2 }} required>
            <MenuItem value="Basement">Basement</MenuItem>
            <MenuItem value="Ground Floor">Ground Floor</MenuItem>
            <MenuItem value="1st Floor">1st Floor</MenuItem>
            <MenuItem value="2nd Floor">2nd Floor</MenuItem>
            <MenuItem value="3rd Floor">3rd Floor</MenuItem>
            <MenuItem value="3rd Floor">4rd Floor</MenuItem>
          </TextField>
          <TextField 
            fullWidth 
            label="Sharing Type" 
            name="sharingType" 
            value={roomData.sharingType} 
            onChange={handleChange} 
            select 
            sx={{ mb: 2 }}
            required
          >
            <MenuItem value="1">Single Sharing (1 Person)</MenuItem>
            <MenuItem value="2">Double Sharing (2 People)</MenuItem>
            <MenuItem value="3">Triple Sharing (3 People)</MenuItem>
            <MenuItem value="4">Quadruple Sharing (4 People)</MenuItem>
          </TextField>
          
          <TextField
            fullWidth
            label="Rent"
            name="rent"
            value={roomData.rent}
            onChange={handleChange}
            type="number"
            onFocus={() => setIsRentFocused(true)}
            onBlur={() => setIsRentFocused(false)}
            InputProps={{
              startAdornment: (isRentFocused || roomData.rent) ? (
                <InputAdornment position="start">₹</InputAdornment>
              ) : null,
            }}
            sx={{ mb: 2 }}
            required
          />          
          <TextField fullWidth label="Area (Sqft)" name="area" value={roomData.area} onChange={handleChange} type="number" sx={{ mb: 2 }} />
          <TextField fullWidth label="Remarks" name="remarks" value={roomData.remarks} onChange={handleChange} sx={{ mb: 2 }} />
        </Box>

        <Box sx={{ mb: 3, mx: 1, bgcolor: '#fff', p: 2, borderRadius: '8px' }}>
          <Typography variant="h6" required>Room Facilities</Typography>
          {['AC', 'Table', 'TV', 'Washroom', 'Balcony', 'Fridge', 'Almirah', 'Chair', 'Food', 'Microwave', 'Geyser', 'Laundry', 'CCTV', 'Toilet', 'Cooler'].map((facility) => (
            <FormControlLabel
              key={facility}
              control={
                <Checkbox
                  checked={roomData.facilities.includes(facility)}
                  onChange={handleFacilitiesChange}
                  value={facility}
                />
              }
              label={facility}
              
            />
          ))}
          
        </Box>

        <Box sx={{ mb: 3, mx: 1, bgcolor: '#fff', p: 2, borderRadius: '8px' }}>
          <Typography variant="h6">Choose type of Room</Typography>
          {['Corner Room', 'Large Room', 'Ventilation', 'Furnished', 'Unfurnished', 'Semi Furnished', 'Short-Term', 'Long-Term', 'Premium Room', 'Basic Room', 'Double Sharing'].map((type) => (
            <FormControlLabel
              key={type}
              control={
                <Checkbox
                  value={type}
                  onChange={handleRoomTypesChange}
                  checked={roomData.roomTypes.includes(type)}
                />
              }
              label={type}
              
            />
          ))}
        </Box>

        <Box sx={{ mb: 3, mx: 1, bgcolor: '#fff', p: 2, borderRadius: '8px' }}>
          <Typography variant="h6" sx={{ mb: 1 }}>Electricity Reading</Typography>
          <TextField fullWidth label="Last Reading" name="lastAddedReading" value={roomData.lastAddedReading} onChange={handleChange} sx={{ mb: 2 }} />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Last Reading Date"
          value={roomData.readingDate}
          onChange={handleDateChange}
          slotProps={{
            textField: {
              fullWidth: true,
              sx: { mb: 2 },
            },
          }}
        />
      </LocalizationProvider>
        </Box>
        <Box sx={{ mb: 3, mx: 1, bgcolor: '#fff', p: 2, borderRadius: '8px' }}>
          <Typography variant="h6">Room Media</Typography>
          <Button variant="outlined" component="label" sx={{ mb: 2 }}>
            Upload Media
            <input type="file" hidden multiple onChange={handleMediaChange} accept="image/*, video/*" />
          </Button>
          <List>
            {media.map((file, index) => (
              <ListItem key={index}>
                <ListItemText primary={file.name} secondary={`${(file.size / 1024 / 1024).toFixed(2)} MB`} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={() => removeMedia(index)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>


        

        <Box sx={{ display: 'flex',height:'48px', justifyContent: 'center', my: 4 }}>
          <Button
            variant='contained'
            type="submit"
            sx={{
              bgcolor:colors.teal,
              color:colors.secondary,
              minWidth: '350px',

              py: 1.5,
              px: 6,
              borderRadius: '12px',
              fontSize: '1.1rem',
              textTransform: 'none',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              '&:hover': {
                boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
                transform: 'translateY(-1px)'
              }
            }}
          >
            Add Room
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default AddRoom;
