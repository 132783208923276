import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { 
  Box, 
  Card, 
  IconButton, 
  InputAdornment, 
  TextField, 
  Typography,
  Menu,
  MenuItem,
  Collapse,
  ButtonBase,
  Grid,
  Divider
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AddIcon from '@mui/icons-material/Add';
import SortIcon from '@mui/icons-material/Sort';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PaymentsIcon from '@mui/icons-material/Payments';
import colors from '../../styles/colors';

const DuesTab = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [selectedSort, setSelectedSort] = useState('nameAsc');
  const [expandedId, setExpandedId] = useState(null);

  // Get data directly from Redux store
  const { dues, rooms, tenants } = useSelector(state => state.property);

  // Filter pending dues
  const pendingDues = dues.filter(due => due.status === 'Pending');

  // Get all unique floors from rooms
  const floors = [...new Set(rooms.map(room => room.floor))];

  const sortOptions = [
    { label: 'Name (A-Z)', value: 'nameAsc' },
    { label: 'Name (Z-A)', value: 'nameDesc' },
    { label: 'Room (A-Z)', value: 'roomAsc' },
    { label: 'Room (Z-A)', value: 'roomDesc' },
    ...floors.map(floor => ({ label: `Floor: ${floor}`, value: `floor_${floor}` }))
  ];

  const getDueDateStatus = (dueDate) => {
    const today = new Date();
    const due = new Date(dueDate);
    const diffTime = due - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays < 0) {
      return { text: `Overdue by ${Math.abs(diffDays)} days`, color: '#FF4B4B' };
    } else if (diffDays === 0) {
      return { text: 'Due today', color: '#FFA500' };
    } else {
      return { text: `Due in ${diffDays} days`, color: colors.teal };
    }
  };

  const handleRecordPayment = (dueData) => {
    navigate(`/record-payment/tenant/${dueData.tenant._id}`, {
      state: {
        tenantName: getTenantName(dueData.tenant._id),
        dueAmount: dueData.totalDue,
        dueType: 'all',
      }
    });
  };

  const handleSendReminder = (dueData) => {
    const tenant = tenants.find(t => t.tenant._id === dueData.tenant._id);
    if (tenant) {
      const message = `Dear ${getTenantName(dueData.tenant._id)}, you have pending dues of ₹${Math.floor(dueData.totalDue)}. Please clear them at your earliest.`;
      const whatsappUrl = `https://wa.me/${tenant.tenant.phone}?text=${encodeURIComponent(message)}`;
      window.open(whatsappUrl, '_blank');
    }
  };

  // Get tenant name from current tenant state
  const getTenantName = (tenantId) => {
    const tenant = tenants.find(t => t.tenant._id === tenantId);
    return tenant?.tenant?.name || 'Unknown Tenant';
  };

  // Get room name from current room state
  const getRoomName = (roomId) => {
    const room = rooms.find(r => r._id === roomId);
    return room?.name || 'Unknown Room';
  };

  // Group and process dues by tenant
  const processedDues = React.useMemo(() => {
    const duesMap = new Map();

    pendingDues.forEach(due => {
      if (!duesMap.has(due.tenant._id)) {
        duesMap.set(due.tenant._id, {
          tenant: { _id: due.tenant._id },
          room: due.room,
          dues: [],
          totalDue: 0
        });
      }
      const dueGroup = duesMap.get(due.tenant._id);
      dueGroup.dues.push(due);
      dueGroup.totalDue += (due.dueAmount || 0);
    });

    let result = Array.from(duesMap.values());

    // Apply search filter
    if (searchTerm) {
      result = result.filter(dueGroup => 
        getTenantName(dueGroup.tenant._id).toLowerCase().includes(searchTerm.toLowerCase()) ||
        getRoomName(dueGroup.room._id).toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply sorting/filtering
    if (selectedSort.startsWith('floor_')) {
      const floor = selectedSort.split('_')[1];
      result = result.filter(dueGroup => {
        const room = rooms.find(r => r._id === dueGroup.room._id);
        return room?.floor === floor;
      });
    } else {
      // Apply regular sorting
      result.sort((a, b) => {
        switch (selectedSort) {
          case 'nameDesc':
            return getTenantName(b.tenant._id).localeCompare(getTenantName(a.tenant._id));
          case 'roomAsc':
            return getRoomName(a.room._id).localeCompare(getRoomName(b.room._id));
          case 'roomDesc':
            return getRoomName(b.room._id).localeCompare(getRoomName(a.room._id));
          case 'nameAsc':
          default:
            return getTenantName(a.tenant._id).localeCompare(getTenantName(b.tenant._id));
        }
      });
    }

    return result;
  }, [pendingDues, searchTerm, selectedSort, tenants, rooms]);

  return (
    <Box>
      {/* Search and Sort Section */}


      <Box sx={{ 
  mt: 0, 
  mb: 2, 
  display: 'flex', 
  gap: 1.5, 
  alignItems: 'center' // Ensure vertical alignment
}}>
  <TextField
    fullWidth
    placeholder="Search tenants..."
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon sx={{ color: colors.teal, fontSize: 20 }} />
        </InputAdornment>
      ),
      sx: { // Style the input directly
        height: 45, // Match height with icon button
        padding: '0 12px', // Reduce horizontal padding
      }
    }}
    sx={{
      '& .MuiOutlinedInput-root': {
        bgcolor: 'white',
        borderRadius: 2,
        '& fieldset': { // Style the outline
          borderColor: 'rgba(0, 0, 0, 0.1)',
        },
        '&:hover fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.2)',
        },
        '&.Mui-focused fieldset': {
          borderColor: colors.teal,
        }
      },
      '& .MuiInputBase-input': { // Style the input text
        padding: '8px 0', // Reduce vertical padding
        fontSize: '0.875rem', // Slightly smaller text
      }
    }}
  />
  <IconButton 
      onClick={(e) => setSortAnchorEl(e.currentTarget)}
      sx={{ 
      bgcolor: sortAnchorEl ? colors.teal : 'white',
      width: 45,
      height: 45,
      borderRadius: '50%',
      padding: 0,
      boxShadow: '0 1px 3px rgba(0,0,0,0.1)', // Optional: add slight shadow
      '&:hover': { 
        bgcolor: sortAnchorEl ? colors.darkteal : 'white' 
      }
    }}
  >
    <SortIcon 
      sx={{ 
        color: sortAnchorEl ? 'white' : colors.teal,
        fontSize: 20
      }} 
    />
  </IconButton>
</Box>

      {/* Sort Menu */}
      <Menu
        anchorEl={sortAnchorEl}
        open={Boolean(sortAnchorEl)}
        onClose={() => setSortAnchorEl(null)}
        PaperProps={{
          sx: {
            mt: 1,
            borderRadius: 2,
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
          }
        }}
      >
        {sortOptions.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => {
              setSelectedSort(option.value);
              setSortAnchorEl(null);
            }}
            selected={selectedSort === option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>

      {/* Due Cards */}
      {processedDues.map((dueGroup, index) => (
        <Card
          key={`${dueGroup.tenant._id}-${index}`}
          sx={{
            mb: 2,
            p: 2,
            borderRadius: 3,
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
          }}
        >
          {/* Card Header */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{
              width: 48,
              height: 48,
              borderRadius: 2,
              bgcolor: `${colors.teal}15`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '1.25rem',
              fontWeight: 600,
              color: colors.teal,
            }}>
              {getTenantName(dueGroup.tenant._id).charAt(0)}
            </Box>

            <Box sx={{ flex: 1 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                }}
              >
                {getTenantName(dueGroup.tenant._id)}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: 'text.secondary' }}
              >
                Room {getRoomName(dueGroup.room._id)}
              </Typography>
            </Box>

            <Box sx={{ textAlign: 'right', pr: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{ color: '#FF4B4B', fontWeight: 600 }}
              >
                ₹{Math.floor(dueGroup.totalDue).toLocaleString()}
              </Typography>
              <Typography
                variant="caption"
                sx={{ color: 'text.secondary' }}
              >
                Total Due
              </Typography>
            </Box>

            <IconButton 
              size="small"
              onClick={() => setExpandedId(expandedId === dueGroup.tenant._id ? null : dueGroup.tenant._id)}
              sx={{ color: colors.teal }}
            >
              {expandedId === dueGroup.tenant._id ? 
                <KeyboardArrowUpIcon /> : 
                <KeyboardArrowDownIcon />
              }
            </IconButton>
          </Box>

          {/* Expanded Details */}
          <Collapse in={expandedId === dueGroup.tenant._id}>
            <Box sx={{ 
              mt: 2,
              bgcolor: colors.lightTeal,
              borderRadius: 2,
              p: 2
            }}>
              {dueGroup.dues.map((due, dueIndex) => {
                const dueStatus = getDueDateStatus(due.dueDate);
                return (
                  <Box key={dueIndex}>
                    <Grid container spacing={2} sx={{ py: 1 }}>
                      <Grid item xs={4}>
                        <Typography variant="body2" sx={{ fontWeight: 500 }}>
                          {due.dueType}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            color: dueStatus.color,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                            fontSize: '0.75rem'
                          }}
                        >
                          <CalendarMonthIcon sx={{ fontSize: 16 }} />
                          {dueStatus.text}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            color: '#FF4B4B', 
                            fontWeight: 600,
                            textAlign: 'right'
                          }}
                        >
                          ₹{Math.floor(due.dueAmount).toLocaleString()}
                        </Typography>
                      </Grid>
                    </Grid>
                    {dueIndex < dueGroup.dues.length - 1 && (
                      <Divider sx={{ my: 1, borderStyle: 'dashed' }} />
                    )}
                  </Box>
                );
              })}
            </Box>
          </Collapse>

          {/* Action Buttons */}
          <Box sx={{ 
            display: 'flex', 
            gap: 2, 
            mt: 2 
          }}>
            <ButtonBase
              onClick={() => handleRecordPayment(dueGroup)}
              sx={{
                flex: 1,
                py: 1,
                px: 2,
                bgcolor: colors.teal,
                color: 'white',
                borderRadius: 2,
                fontSize: '0.75rem',
                whiteSpace: 'nowrap',
                '&:hover': {
                  bgcolor: colors.darkteal,
                },
              }}
            >
              Record Payment
            </ButtonBase>

            <ButtonBase
              onClick={() => handleSendReminder(dueGroup)}
              sx={{
                flex: 1,
                py: 1,
                px: 2,
                bgcolor: `${colors.teal}15`,
                color: colors.teal,
                borderRadius: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '0.75rem',
                whiteSpace: 'nowrap',
                '&:hover': {
                  bgcolor: `${colors.teal}25`,
                },
              }}
            >
              <WhatsAppIcon sx={{ mr: 1, fontSize: 16 }} />
              Send Reminder
            </ButtonBase>
          </Box>
        </Card>
      ))}

      {/* Add Due FAB */}
      <Box
        onClick={() => navigate('/tenants')}
        sx={{
          position: 'fixed',
          right: 16,
          bottom: 80,
          zIndex: 1000,
          bgcolor: colors.teal,
          color: 'white',
          borderRadius: '12px',
          py: 1,
          px: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
          cursor: 'pointer',
          transition: 'all 0.2s',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 6px 16px rgba(0,0,0,0.2)',
          },
        }}
      >
        <AddIcon sx={{ fontSize: 20 }} />
        <Typography sx={{ 
          fontSize: '0.875rem',
          fontWeight: 500,
          whiteSpace: 'nowrap'
        }}>
          Add Due
        </Typography>
      </Box>
    </Box>
  );
};

export default DuesTab;