import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { 
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Chip,
  Card,
  Menu,
  MenuItem,
  Collapse,
  ButtonBase,
  Grid,
  Divider
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import DownloadIcon from '@mui/icons-material/Download';
import ShareIcon from '@mui/icons-material/Share';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PaymentsIcon from '@mui/icons-material/Payments';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import { selectPaidDues } from '../../features/propertySlice';
import { BASE_URL } from '../../utils/IP';
import colors from '../../styles/colors';

const PaymentDetailRow = ({ icon: Icon, label, value }) => (
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center',
    gap: 2,
    py: 1
  }}>
    <Icon sx={{ color: colors.teal, fontSize: 20 }} />
    <Typography variant="body2" color="text.secondary" sx={{ flex: 1 }}>
      {label}
    </Typography>
    <Typography variant="body2" sx={{ fontWeight: 500, color: colors.primary }}>
      {value}
    </Typography>
  </Box>
);

const CollectionsTab = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [expandedId, setExpandedId] = useState(null);
  
  // Get collections and tenants from Redux state
  const collections = useSelector(selectPaidDues) || []; // Add default empty array
  const { tenants, rooms } = useSelector(state => state.property);

  // Create a map of tenant IDs to tenant details for efficient lookup
  const tenantMap = useMemo(() => {
    return tenants.reduce((acc, tenant) => {
      if (tenant?.tenant?._id) {
        acc[tenant.tenant._id] = tenant.tenant;
      }
      return acc;
    }, {});
  }, [tenants]);

  // Create a map of room IDs to room details
  const roomMap = useMemo(() => {
    return rooms.reduce((acc, room) => {
      acc[room._id] = room;
      return acc;
    }, {});
  }, [rooms]);

  const filters = [
    { label: 'All', value: 'all' },
    { label: 'Last 7 days', value: '7days' },
    { label: 'This month', value: 'thisMonth' },
    { label: 'Last month', value: 'lastMonth' }
  ];

  const sortedAndFilteredCollections = useMemo(() => {
    return collections
      .sort((a, b) => new Date(b.paymentDateTime) - new Date(a.paymentDateTime))
      .filter((collection) => {
        const tenant = tenantMap[collection.tenant._id];
        if (!tenant) return false;
        if (!collection || !collection.tenant) return false; // Add null check

        const matchesSearch = tenant.name.toLowerCase().includes(searchTerm.toLowerCase());
        if (!matchesSearch) return false;

        const paymentDate = new Date(collection.paymentDateTime);
        const now = new Date();

        switch (selectedFilter) {
          case '7days':
            const sevenDaysAgo = new Date(now.setDate(now.getDate() - 7));
            return paymentDate >= sevenDaysAgo;
          case 'thisMonth':
            return paymentDate.getMonth() === now.getMonth() && 
                   paymentDate.getFullYear() === now.getFullYear();
          case 'lastMonth':
            const lastMonth = new Date(now.setMonth(now.getMonth() - 1));
            return paymentDate.getMonth() === lastMonth.getMonth() && 
                   paymentDate.getFullYear() === lastMonth.getFullYear();
          default:
            return true;
        }
      });
  }, [collections, tenantMap, searchTerm, selectedFilter]);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterSelect = (value) => {
    setSelectedFilter(value);
    handleFilterClose();
  };

  const handleShare = async (collection) => {
    if (navigator.share && collection.receipt?.pdfUrl) {
      try {
        const tenant = tenantMap[collection.tenant._id];
        await navigator.share({
          title: 'Payment Receipt',
          text: `Receipt for payment of ₹${collection.paymentAmount} from ${tenant?.name || 'Unknown'} on ${new Date(collection.paymentDateTime).toLocaleDateString()}`,
          url: `${BASE_URL}${collection.receipt.pdfUrl}`
        });
      } catch (error) {
        console.error('Error sharing receipt:', error);
      }
    }
  };

  const handleDownload = (collection) => {
    if (collection?.receipt?.pdfUrl) {
      window.open(`${BASE_URL}${collection.receipt.pdfUrl}`, '_blank');
    }
  };

  const formatDateTime = (dateString) => {
    return new Date(dateString).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const PaymentMethodIcon = ({ method = '' }) => (
    <Box sx={{ 
      width: 40, 
      height: 40, 
      borderRadius: 2,
      bgcolor: `${colors.teal}15`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: colors.teal,
      fontWeight: 600,
    }}>
      {method[0] || '?'}
    </Box>
  );

  if (collections.length === 0) {
    return (
      <Box sx={{ 
        textAlign: 'center', 
        mt: 8,
        p: 3,
        bgcolor: 'white',
        borderRadius: 3
      }}>
        <Typography color="text.secondary">
          No collections found
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
          <Box sx={{ 
            mt: 0, 
            mb: 2, 
            display: 'flex', 
            gap: 1.5, 
            alignItems: 'center' // Ensure vertical alignment
          }}>
            <TextField
              fullWidth
              placeholder="Search tenants..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: colors.teal, fontSize: 20 }} />
                  </InputAdornment>
                ),
                sx: { // Style the input directly
                  height: 45, // Match height with icon button
                  padding: '0 12px', // Reduce horizontal padding
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  bgcolor: 'white',
                  borderRadius: 2,
                  '& fieldset': { // Style the outline
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0.2)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.teal,
                  }
                },
                '& .MuiInputBase-input': { // Style the input text
                  padding: '8px 0', // Reduce vertical padding
                  fontSize: '0.875rem', // Slightly smaller text
                }
              }}
            />
            <IconButton 
              onClick={handleFilterClick}
              sx={{ 
                bgcolor: filterAnchorEl ? colors.teal : 'white',
                width: 45,
                height: 45,
                borderRadius: '50%',
                padding: 0,
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)', // Optional: add slight shadow
                '&:hover': { 
                  bgcolor: filterAnchorEl ? colors.darkteal : 'white' 
                }
              }}
            >
              <FilterListIcon 
                sx={{ 
                  color: filterAnchorEl ? 'white' : colors.teal,
                  fontSize: 20
                }} 
              />
            </IconButton>
          </Box>
      

      {selectedFilter !== 'all' && (
        <Box sx={{ mb: 2 }}>
          <Chip
            label={filters.find(f => f.value === selectedFilter)?.label}
            onDelete={() => setSelectedFilter('all')}
            color="primary"
            sx={{ 
              bgcolor: `${colors.teal}15`,
              color: colors.teal,
              '& .MuiChip-deleteIcon': {
                color: colors.teal
              }
            }}
          />
        </Box>
      )}

      {sortedAndFilteredCollections.map((collection, index) => {
        const tenant = tenantMap[collection.tenant._id];
        const room = roomMap[collection.room?._id];
        if (!tenant) return null;

        return (
          <Card
            key={index}
            sx={{
              mb: 2,
              borderRadius: 3,
              overflow: 'hidden',
              bgcolor: 'white',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            }}
          >
            <ButtonBase 
              onClick={() => setExpandedId(expandedId === collection._id ? null : collection._id)}
              sx={{ 
                width: '100%', 
                textAlign: 'left',
                p: 2
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <PaymentMethodIcon method={collection.paymentMode} />
                  
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.primary }}>
                      {tenant.name}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {collection.dueType}
                    </Typography>
                  </Box>

                  <Box sx={{ textAlign: 'right' }}>
                    <Typography variant="subtitle1" sx={{ color: colors.teal, fontWeight: 600 }}>
                      ₹{collection.paymentAmount.toLocaleString()}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {new Date(collection.paymentDateTime).toLocaleDateString()}
                    </Typography>
                  </Box>

                  {expandedId === collection._id ? 
                    <KeyboardArrowUpIcon sx={{ color: colors.teal }} /> : 
                    <KeyboardArrowDownIcon sx={{ color: colors.teal }} />
                  }
                </Box>
              </Box>
            </ButtonBase>

            <Collapse in={expandedId === collection._id}>
              <Box sx={{ px: 3, pb: 3 }}>
                <Divider sx={{ my: 2 }} />
                
                <Box sx={{ mb: 2 }}>
                  <Typography 
                    variant="subtitle2" 
                    sx={{ 
                      color: colors.teal,
                      fontWeight: 600,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      mb: 2
                    }}
                  >
                    <ReceiptLongIcon sx={{ fontSize: 20 }} />
                    Payment Details
                  </Typography>

                  <Box sx={{ 
                    bgcolor: `${colors.teal}08`,
                    borderRadius: 2,
                    p: 2
                  }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <PaymentDetailRow
                          icon={PersonIcon}
                          label="Tenant Name"
                          value={tenant.name}
                        />
                        <PaymentDetailRow
                          icon={HomeIcon}
                          label="Room Number"
                          value={room?.name || 'N/A'}
                        />
                        <PaymentDetailRow
                          icon={PaymentsIcon}
                          label="Payment Mode"
                          value={collection.paymentMode}
                        />
                        <PaymentDetailRow
                          icon={EventIcon}
                          label="Payment Date & Time"
                          value={formatDateTime(collection.paymentDateTime)}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Box sx={{ mb: 2 }}>
                  <Typography 
                    variant="subtitle2" 
                    sx={{ 
                      mb: 1,
                      color: colors.primary,
                      fontWeight: 500
                    }}
                  >
                    Due Details
                  </Typography>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    bgcolor: `${colors.teal}08`,
                    borderRadius: 2,
                    p: 2
                  }}>
                    <Box>
                      <Typography variant="body2" color="text.secondary">
                        Due Type
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 500, color: colors.primary }}>
                        {collection.dueType}
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'right' }}>
                      <Typography variant="body2" color="text.secondary">
                        Amount Paid
                      </Typography>
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          fontWeight: 600,
                          color: colors.teal
                        }}
                      >
                        ₹{collection.paymentAmount.toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ 
                  display: 'flex',
                  gap: 2,
                  mt: 3
                }}>
                  <ButtonBase
                    onClick={() => handleDownload(collection)}
                    sx={{
                      flex: 1,
                      p: 1.5,
                      borderRadius: 2,
                      bgcolor: colors.teal,
                      color: 'white',
                      transition: 'all 0.2s ease',
                      '&:hover': { 
                        bgcolor: colors.darkteal,
                        transform: 'translateY(-2px)'
                      }
                    }}
                  >
                    <DownloadIcon sx={{ mr: 1, fontSize: 18 }} />
                    <Typography variant="body2" >Download</Typography>
                  </ButtonBase>

                  <ButtonBase
                    onClick={() => handleShare(collection)}
                    sx={{
                      flex: 1,
                      p: 1.5,
                      borderRadius: 2,
                      bgcolor: `${colors.teal}15`,
                      color: colors.teal,
                      transition: 'all 0.2s ease',
                      '&:hover': { 
                        bgcolor: `${colors.teal}25`,
                        transform: 'translateY(-2px)'
                      }
                    }}
                  >
                    <ShareIcon sx={{ mr: 1, fontSize: 18 }} />
                    <Typography variant="body2">Share Receipt</Typography>
                  </ButtonBase>
                </Box>
              </Box>
            </Collapse>
          </Card>
        );
      })}

      <Menu
        anchorEl={filterAnchorEl}
        open={Boolean(filterAnchorEl)}
        onClose={handleFilterClose}
        PaperProps={{
          sx: {
            mt: 1,
            borderRadius: 2,
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
          }
        }}
      >
        {filters.map((filter) => (
          <MenuItem
            key={filter.value}
            onClick={() => handleFilterSelect(filter.value)}
            selected={selectedFilter === filter.value}
          >
            {filter.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default CollectionsTab;