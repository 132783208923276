import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { 
  Container, 
  Box, 
  Typography, 
  IconButton, 
  TextField, 
  Button, 
  Paper, 
  MenuItem 
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { updateExpense } from '../../features/propertySlice';
import colors from '../../styles/colors';
import CentralDialog from '../../utils/CentralDialog';

const expenseCategories = [
  { label: 'Maintenance', value: 'Maintenance' },
  { label: 'Utility Bills', value: 'Utility Bills' },
  { label: 'Repairs', value: 'Repairs' },
  { label: 'Cleaning', value: 'Cleaning' },
  { label: 'Other', value: 'Other' },
];

const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

const UpdateExpense = () => {
  const { expenseId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { expenseData: initialExpenseData } = location.state || {};

  const [expenseData, setExpenseData] = useState({
    category: initialExpenseData?.category || '',
    amount: initialExpenseData?.amount || '',
    paidBy: initialExpenseData?.paidBy || '',
    paidDate: formatDateForInput(initialExpenseData?.paidDate) || '',
    description: initialExpenseData?.description || '',
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({});

  const handleBackClick = () => {
    navigate('/rent-due-collection', { state: { activeTab: 3 } });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setExpenseData(prev => ({
      ...prev,
      [name]: name === 'paidDate' ? formatDateForInput(value) : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDialogConfig({
      title: 'Confirm Update',
      content: 'Are you sure you want to update this expense?',
      type: 'confirm',
      onConfirm: confirmUpdate,
    });
    setDialogOpen(true);
  };

  const confirmUpdate = async () => {
    try {
      await dispatch(updateExpense({ expenseId, expenseData })).unwrap();
      setDialogConfig({
        title: 'Success',
        content: 'Expense updated successfully',
        type: 'success',
        });
        setDialogOpen(true);
        // Set a timeout to navigate after 2 seconds
        setTimeout(() => {
        navigate('/rent-due-collection', { state: { activeTab: 3 } });
        }, 2000);
    } catch (error) {
      console.error('Error updating expense:', error);
      setDialogConfig({
        title: 'Error',
        content: 'Failed to update expense. Please try again.',
        type: 'error',
      });
    }
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    if (dialogConfig.type === 'success') {
      navigate('/rent-due-collection', { state: { activeTab: 3 } });
    }
  };

  const textFieldStyle = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.darkteal,
      },
      '&:hover fieldset': {
        borderColor: 'grey',
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.darkteal,
      },
    },
    '& .MuiOutlinedInput-input': {
      color: colors.darkteal,
    },
    '& .MuiInputLabel-outlined': {
      color: 'grey',
      '&.Mui-focused': {
        color: 'grey',
      },
    },
  };

  return (
    <Container maxWidth="sm" disableGutters>
      <Box
        sx={{
          bgcolor: colors.secondary,
          pb: 2,
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
          mx: 0,
          px: 2,
          color: colors.primary,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: 2 }}>
          <IconButton onClick={handleBackClick} sx={{ color: colors.primary }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ fontSize: '1.2rem', textAlign: 'left', flexGrow: 1 }}>Update Expense</Typography>
          <Box sx={{ width: 24 }} />
        </Box>
      </Box>

      <Paper sx={{ mt: 2, p: 2 }}>
        <Box sx={{ mt: 3 }}>
          <TextField
            select
            fullWidth
            label="Expense Category"
            name="category"
            value={expenseData.category}
            onChange={handleChange}
            required
            sx={{ mb: 2, ...textFieldStyle }}
          >
            {expenseCategories.map((category) => (
              <MenuItem key={category.value} value={category.value}>
                {category.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            label="Amount"
            variant="outlined"
            sx={{ mb: 2, ...textFieldStyle }}
            name="amount"
            type="number"
            value={expenseData.amount}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            label="Paid By"
            variant="outlined"
            sx={{ mb: 2, ...textFieldStyle }}
            name="paidBy"
            value={expenseData.paidBy}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            label="Paid Date"
            variant="outlined"
            type="date"
            sx={{ mb: 2, ...textFieldStyle }}
            InputLabelProps={{
              shrink: true,
            }}
            name="paidDate"
            value={expenseData.paidDate}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            multiline
            rows={4}
            sx={{ mb: 2, ...textFieldStyle }}
            name="description"
            value={expenseData.description}
            onChange={handleChange}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ backgroundColor: colors.darkteal, textTransform: 'none' }}
            onClick={handleSubmit}
          >
            Update Expense
          </Button>
        </Box>
      </Paper>

      <CentralDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={dialogConfig.title}
        content={dialogConfig.content}
        type={dialogConfig.type}
        onConfirm={dialogConfig.onConfirm}
      />
    </Container>
  );
};

export default UpdateExpense;