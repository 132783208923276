import React,{useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import { CircularProgress } from '@mui/material';
import ScrollToTop from './utils/ScrollToTop'
// Import your components
import Home from './components/home/Home';
import TenantList from './components/tenants/TenantList';
import RoomList from './components/rooms/RoomList';
import TenantProfile from './components/tenants/TenantProfile';
import RoomProfile from './components/rooms/RoomProfile';
import RentDueCollection from './components/money/RentDueCollection';
import Login from './components/home/login';
import Signup from './components/home/signup';
import AddTenant from './components/tenants/AddTenant';
import EditTenant from './components/tenants/EditTenant';
import AddRoom from './components/rooms/AddRoom';
import AddDue from './components/money/AddDue';
import RecordPayment from './components/money/RecordPayment';
import RecordPayment2 from './components/money/RecordPayment2';
import AddProperty from './components/home/AddProperty';
import ComplaintList from './components/complaints/AllComplaints';
import ProtectedRoute from './components/others/ProtectedRoute';
import UpdateDue from './components/money/UpdateDue';
import ComplaintDetails from './components/complaints/ComplaintDetails';
import PastTenantList from './components/tenants/PastTenantList';
import AddExpense from './components/expenses/AddExpense';
import UpdateExpense from './components/expenses/UpdateExpense';
import ElectricityBillCalculator from './components/money/ElectricityBill'
import HomeownerVerificationList from './components/money/HomeownerVerificationList';




// New component to handle app initialization
const AppInitializer = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'APP_INITIALIZED' });
  }, [dispatch]);

  return children;
};

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<CircularProgress />} persistor={persistor}>
        <AppInitializer>
          <Router>
            <ScrollToTop/>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/" element={<ProtectedRoute />}>
                <Route index element={<Home />} />
                <Route path="home" element={<Home />} />
                <Route path="tenants" element={<TenantList />} />
                <Route path="past-tenants" element={<PastTenantList />} />
                <Route path="rooms" element={<RoomList />} />
                <Route path="complaints" element={<ComplaintList />} />
                <Route path="complaint/:complaintId" element={<ComplaintDetails />} />
                <Route path="tenant-profile/:tenantId" element={<TenantProfile />} />
                <Route path="room-profile/:roomId" element={<RoomProfile />} />
                <Route path="rent-due-collection" element={<RentDueCollection />} />
                <Route path="add-tenant" element={<AddTenant />} />
                <Route path="edit-tenant/:tenantId" element={<EditTenant />} />
                <Route path="add-room" element={<AddRoom />} />
                <Route path="add-due" element={<AddDue />} />
                <Route path="record-payment2/:dueId" element={<RecordPayment2 />} />
                <Route path="record-payment/tenant/:tenantId" element={<RecordPayment />} />
                <Route path="add-property" element={<AddProperty />} />
                <Route path="/update-due/:dueId" element={<UpdateDue />} />
                <Route path="/add-expense" element={<AddExpense />} />
                <Route path="/update-expense/:expenseId" element={<UpdateExpense />} />
                <Route path="/electricity-bills" element={<ElectricityBillCalculator />} />
                <Route path="/verification-requests" element={<HomeownerVerificationList />} />
              </Route>
            </Routes>
          </Router>
        </AppInitializer>
      </PersistGate>
    </Provider>
  );
}

export default App;