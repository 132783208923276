import React, { useState, useContext} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import { Container, TextField, Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { addProperty } from '../../features/propertySlice';


const AddProperty = () => {
  const [propertyData, setPropertyData] = useState({
    name: '',
    address: '',
    city: '',
    pincode: '',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const dispatch= useDispatch();

  const handleChange = (e) => {
    setPropertyData({ ...propertyData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(addProperty(propertyData)).unwrap();
      navigate('/home'); // or wherever you want to redirect after adding
    } catch (err) {
      console.error('Failed to add property:', err);
    }
  };

  return (
    <Container>
      <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Add Property
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Property Name"
            name="name"
            autoComplete="name"
            autoFocus
            value={propertyData.name}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="address"
            label="Address"
            id="address"
            autoComplete="address"
            value={propertyData.address}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="city"
            label="City"
            id="city"
            autoComplete="city"
            value={propertyData.city}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="pincode"
            label="Pincode"
            id="pincode"
            autoComplete="pincode"
            value={propertyData.pincode}
            onChange={handleChange}
          />          
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Add Property
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AddProperty;
