import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Box, 
  Container,
  Typography,
  IconButton,
  Card,
  TextField,
  InputAdornment,
  Fade,
  Modal,
  Chip,
  Avatar,
  Badge,
  Collapse,
  ButtonBase,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Alert,
  Snackbar
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  Search as SearchIcon,
  Visibility as VisibilityIcon,
  Close as CloseIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Check as CheckIcon,
  Close as RejectIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { verifyPayment } from '../../features/propertySlice';
import colors from '../../styles/colors';
import { BASE_URL } from '../../utils/IP';

const HomeownerVerificationList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedScreenshot, setSelectedScreenshot] = useState(null);
  const [expandedId, setExpandedId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

// Dialog states
const [confirmDialog, setConfirmDialog] = useState({
  open: false,
  title: '',
  content: '',
  action: null,
  dueId: null,
  status: null,
  tenantName: ''
});

// Feedback states
const [feedbackAlert, setFeedbackAlert] = useState({
  open: false,
  message: '',
  severity: 'success'
});
  const { dues } = useSelector(state => state.property);
  const underVerificationDues = dues.filter(due => due.status === 'UnderVerification');

  const filteredDues = underVerificationDues.filter(due => 
    due.tenant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    due.room.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleVerification = async (dueId, status, tenantName, event) => {
    event.stopPropagation(); // Prevent card expansion when clicking buttons
    try {
      await dispatch(verifyPayment({ dueId, status })).unwrap();
      setSnackbarMessage(
        `Payment ${status === 'Approved' ? 'approved' : 'rejected'} for ${tenantName}`
      );
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Error processing verification');
      setSnackbarOpen(true);
    }
  };

 const handleVerificationClick = (dueId, status, tenantName, event) => {
    event.stopPropagation();
    setConfirmDialog({
      open: true,
      title: `Confirm ${status}`,
      content: `Are you sure you want to ${status.toLowerCase()} the payment request from ${tenantName}?`,
      dueId,
      status,
      tenantName
    });
  };

  const handleConfirmVerification = async () => {
    const { dueId, status, tenantName } = confirmDialog;
    setConfirmDialog(prev => ({ ...prev, open: false }));

    try {
      await dispatch(verifyPayment({ dueId, status })).unwrap();
      setFeedbackAlert({
        open: true,
        message: `Payment successfully ${status.toLowerCase()} for ${tenantName}`,
        severity: 'success'
      });
    } catch (error) {
      setFeedbackAlert({
        open: true,
        message: `Failed to ${status.toLowerCase()} payment: ${error.message || 'Unknown error'}`,
        severity: 'error'
      });
    }
  };

  const handleViewScreenshot = (screenshot, event) => {
    event.stopPropagation();
    setSelectedScreenshot(screenshot);
    setPreviewOpen(true);
  };

  

  return (
    <Container maxWidth="sm" disableGutters sx={{ pb: 8 }}>
      {/* Header */}
      <Box
        sx={{
          bgcolor: colors.secondary,
          position: 'sticky',
          top: 0,
          left: 0,
          right: 0,
          width: '100vw',
          zIndex: 1100,
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        }}
      >
        <Box sx={{ px: 2, py: 2, display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={() => navigate(-1)} sx={{ color: colors.primary }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ ml: 1, color: colors.primary, fontWeight: 600 }}>
            Payment Verification
          </Typography>
          <Box sx={{ ml: 'auto' }}>
            <Badge
              badgeContent={underVerificationDues.length}
              color="warning"
              sx={{ mr: 1 }}
            >
              <Chip
                label="Pending"
                size="small"
                sx={{
                  bgcolor: 'rgba(255, 152, 0, 0.1)',
                  color: '#FF9800',
                  fontWeight: 500
                }}
              />
            </Badge>
          </Box>
        </Box>

        <Box sx={{ px: 2, pb: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search verifications..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: colors.teal }} />
                </InputAdornment>
              ),
            }}
            sx={{
              bgcolor: 'white',
              borderRadius: '12px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                '& fieldset': { borderColor: 'transparent' },
                '&:hover fieldset': { borderColor: 'transparent' },
                '&.Mui-focused fieldset': { borderColor: colors.teal },
              },
            }}
          />
        </Box>
      </Box>

      {/* Content */}
      <Box sx={{ mt: 2, px: 2 }}>
        {filteredDues.length === 0 ? (
          <Box sx={{ 
            textAlign: 'center', 
            mt: 8, 
            p: 3, 
            bgcolor: 'rgba(0,0,0,0.02)',
            borderRadius: 2
          }}>
            <CheckCircleOutlineIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
            <Typography variant="h6" color="text.secondary">
              No pending verifications
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              {searchTerm ? 'Try different search terms' : 'All payment requests have been processed'}
            </Typography>
          </Box>
        ) : (
          filteredDues.map((due, index) => (
            <Fade in={true} key={due._id} timeout={300 + index * 100}>
              <Card sx={{
                mb: 2,
                borderRadius: 3,
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                cursor: 'pointer',
                transition: 'all 0.2s',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                }
              }} onClick={() => setExpandedId(expandedId === due._id ? null : due._id)}>
                {/* Main Content */}
                <Box sx={{ p: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Avatar
                      sx={{
                        bgcolor: `${colors.teal}15`,
                        color: colors.teal,
                        width: 48,
                        height: 48
                      }}
                    >
                      {due.tenant.name[0]}
                    </Avatar>

                    <Box sx={{ flex: 1 }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                        {due.tenant.name}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        Room {due.room.name}
                      </Typography>
                    </Box>

                    <Box sx={{ textAlign: 'right' }}>
                      <Typography variant="subtitle2" sx={{ color: colors.teal, fontWeight: 600 }}>
                        ₹{due.paymentRequest.amount}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {due.paymentRequest.paymentMode}
                      </Typography>
                    </Box>

                    <IconButton 
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setExpandedId(expandedId === due._id ? null : due._id);
                      }}
                    >
                      {expandedId === due._id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </Box>

                  {/* Expandable Content */}
                  <Collapse in={expandedId === due._id}>
                    <Box sx={{ mt: 2, ml: 2, borderLeft: `2px solid ${colors.lightTeal}`, pl: 2 }}>
                      <Typography variant="body2" color="text.secondary" paragraph>
                        Due Type: <strong>{due.dueType}</strong>
                      </Typography>
                      <Typography variant="body2" color="text.secondary" paragraph>
                        Request Date: <strong>{new Date(due.paymentRequest.requestDate).toLocaleDateString()}</strong>
                      </Typography>
                      {due.paymentRequest.description && (
                        <Typography variant="body2" color="text.secondary" paragraph>
                          Description: <strong>{due.paymentRequest.description}</strong>
                        </Typography>
                      )}
                      
                      {due.paymentRequest.paymentScreenshot && (
                        <ButtonBase
                          onClick={(e) => handleViewScreenshot(due.paymentRequest.paymentScreenshot, e)}
                          sx={{
                            width: '100%',
                            p: 1,
                            bgcolor: `${colors.teal}10`,
                            borderRadius: 1,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            mb: 2
                          }}
                        >
                          <VisibilityIcon sx={{ color: colors.teal }} />
                          <Typography variant="body2" sx={{ color: colors.teal }}>
                            View Payment Screenshot
                          </Typography>
                        </ButtonBase>
                      )}
                    </Box>
                  </Collapse>
                    {/* Action Buttons */}
    <Box sx={{ 
      display: 'flex', 
      gap: 1, 
      mt: 2,
      borderTop: '1px solid',
      borderColor: 'divider',
      pt: 2
    }}>
      <ButtonBase
        onClick={(e) => handleVerificationClick(due._id, 'Approved', due.tenant.name, e)}
        sx={{
          flex: 1,
          py: 1,
          bgcolor: colors.teal,
          color: 'white',
          borderRadius: 2,
          fontSize: '0.875rem',
          '&:hover': { bgcolor: colors.darkteal }
        }}
      >
        <CheckIcon sx={{ fontSize: 18, mr: 0.5 }} />
        Approve
      </ButtonBase>

      <ButtonBase
        onClick={(e) => handleVerificationClick(due._id, 'Rejected', due.tenant.name, e)}
        sx={{
          flex: 1,
          py: 1,
          bgcolor: '#FF4B4B',
          color: 'white',
          borderRadius: 2,
          fontSize: '0.875rem',
          '&:hover': { bgcolor: '#E53935' }
        }}
      >
        <RejectIcon sx={{ fontSize: 18, mr: 0.5 }} />
        Reject
      </ButtonBase>
    </Box>
                    <Dialog
        open={confirmDialog.open}
        onClose={() => setConfirmDialog(prev => ({ ...prev, open: false }))}
      >
        <DialogTitle sx={{ 
          color: confirmDialog.status === 'Approved' ? colors.teal : '#FF4B4B',
          fontWeight: 600 
        }}>
          {confirmDialog.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {confirmDialog.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button 
            onClick={() => setConfirmDialog(prev => ({ ...prev, open: false }))}
            sx={{ color: 'text.secondary' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmVerification}
            variant="contained"
            sx={{
              bgcolor: confirmDialog.status === 'Approved' ? colors.teal : '#FF4B4B',
              '&:hover': {
                bgcolor: confirmDialog.status === 'Approved' ? colors.darkteal : '#E53935'
              }
            }}
          >
            Confirm {confirmDialog.status}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Feedback Alert */}
      <Snackbar
        open={feedbackAlert.open}
        autoHideDuration={6000}
        onClose={() => setFeedbackAlert(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setFeedbackAlert(prev => ({ ...prev, open: false }))}
          severity={feedbackAlert.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {feedbackAlert.message}
        </Alert>
      </Snackbar>
                  
                </Box>
              </Card>
            </Fade>
          ))
        )}
      </Box>

      {/* Screenshot Preview Modal */}
      <Modal
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        closeAfterTransition
      >
        <Fade in={previewOpen}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '600px',
            bgcolor: 'background.paper',
            borderRadius: 3,
            boxShadow: 24,
            p: 2,
          }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
              <IconButton onClick={() => setPreviewOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <img
              src={`${BASE_URL}${selectedScreenshot}`}
              alt="Payment Screenshot"
              style={{
                width: '100%',
                height: 'auto',
                maxHeight: '70vh',
                objectFit: 'contain',
                borderRadius: 8
              }}
            />
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};

export default HomeownerVerificationList;