// src/middleware/websocketMiddleware.js (Homeowner App)

import WebSocketService from '../services/websocketService';
import { fetchAllPropertyData, fetchProperties, fetchTenants, fetchRooms, fetchDues, fetchComplaints,fetchExpenses } from '../features/propertySlice';
import { REHYDRATE } from 'redux-persist';

const websocketMiddleware = store => next => action => {
  if (action.type === 'auth/login/fulfilled') {
    WebSocketService.connect(action.payload);
    setupWebSocketListeners(store);
  }

  if (action.type === 'auth/logout') {
    WebSocketService.disconnect();
    console.log('WebSocket disconnected');
  }

  if (action.type === REHYDRATE) {
    console.log('Store rehydrated, checking WebSocket connection');
    const token = localStorage.getItem('token');
    if (token) {
      WebSocketService.reconnectIfNeeded();
      setupWebSocketListeners(store);
    }
  }

  if (action.type === 'APP_INITIALIZED') {
    console.log('App initialized, checking WebSocket connection');
    const token = localStorage.getItem('token');
    if (token) {
      WebSocketService.reconnectIfNeeded();
      setupWebSocketListeners(store);
    }
  }

  return next(action);
};

function setupWebSocketListeners(store) {
  WebSocketService.on('PROPERTY_UPDATE', (data) => {
    store.dispatch(fetchAllPropertyData(data._id));
  });

  WebSocketService.on('TENANT_UPDATE', () => {
    const state = store.getState();
    const propertyId = state.property.selectedProperty?._id;
    if (propertyId) {
      store.dispatch(fetchTenants(propertyId));
    }
  });

  WebSocketService.on('ROOM_UPDATE', () => {
    const state = store.getState();
    const propertyId = state.property.selectedProperty?._id;
    if (propertyId) {
      store.dispatch(fetchRooms(propertyId));
    }
  });

  WebSocketService.on('DUE_UPDATE', () => {
    const state = store.getState();
    const propertyId = state.property.selectedProperty?._id;
    if (propertyId) {
      store.dispatch(fetchDues(propertyId));
    }
  });

  WebSocketService.on('COMPLAINT_UPDATE', () => {
    const state = store.getState();
    const propertyId = state.property.selectedProperty?._id;
    if (propertyId) {
      store.dispatch(fetchComplaints(propertyId));
    }
  });

  WebSocketService.on('EXPENSE_UPDATE', () => {
    const state = store.getState();
    const propertyId = state.property.selectedProperty?._id;
    if (propertyId) {
      store.dispatch(fetchExpenses(propertyId));
    }
  });
}

export default websocketMiddleware;