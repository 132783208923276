// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ redirectPath = '/login' }) => {
  const { user, isLoading } = useSelector(state => state.auth);

  if (isLoading) {
    return <div>Loading hhihihihi...</div>; // or a spinner
  }

  if (!user ) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;