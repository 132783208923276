import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { 
  Box, 
  Container,
  Typography, 
  IconButton, 
  InputAdornment, 
  TextField,
  Card,
  Grid,
  Chip,
  ButtonBase,
  Badge,
  Menu,
  MenuItem
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterListIcon from '@mui/icons-material/FilterList';
import PersonIcon from '@mui/icons-material/Person';
import { ChevronRight, DoorFrontSharp } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import colors from '../../styles/colors';
import BottomNavigationComponent from '../others/BottomNavigationComponent';


const RoomList = () => {
  const { properties, selectedProperty, loading, tenants, rooms, dues, complaints } = useSelector(state => state.property);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('all');

  const filters = [
    { label: 'All Rooms', value: 'all' },
    { label: 'Vacant Only', value: 'vacant' },
    { label: 'Occupied', value: 'occupied' }
  ];

  const filteredRooms = useMemo(() => {
    return rooms.filter((room) => {
      const matchesSearch = room.name.toLowerCase().includes(searchTerm.toLowerCase());
      if (!matchesSearch) return false;

      switch (selectedFilter) {
        case 'vacant':
          return room.isAvailable;
        case 'occupied':
          return !room.isAvailable;
        default:
          return true;
      }
    });
  }, [rooms, searchTerm, selectedFilter]);

  const totalRooms = rooms.length;
  const vacantRooms = rooms.filter(room => room.isAvailable).length;
  const occupiedRooms = totalRooms - vacantRooms;

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterSelect = (value) => {
    setSelectedFilter(value);
    handleFilterClose();
  };

  return (
    <Box sx={{ pb: 8, bgcolor: '#F5F7FA', minHeight: '100vh' }}>
      {/* Header */}
      <Box sx={{
        bgcolor: 'white',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        position: 'sticky',
        top: 0,
        zIndex: 1100,
      }}>
       <Container disableGutters sx={{ px: 3 }}>
          <Box sx={{ pt: 2, pb: 1 }}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center' 
            }}>
            <Typography variant="h6" sx={{ fontWeight: 600, color: colors.primary }}>
              Rooms
            </Typography>
            <IconButton 
              component={Link} 
              to="/add-room"
              sx={{ color: colors.teal }}
            >
              <AddIcon />
            </IconButton>
          </Box>
        
      

      
        {/* Quick Stats */}
        <Grid container sx={{ mt: 0,mt:3,mb:1, ml: 2, color: colors.primary, fontWeight: 'medium', }}>
        <Grid item xs={4}>
            <Typography variant="body1">Total Rooms:</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{totalRooms}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">Vacant Rooms:</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{vacantRooms}</Typography>
          </Grid>
        </Grid>

        {/* Search and Filter */}
        <Box sx={{ 
              mt: 3, 
              mb: 2, 
              display: 'flex', 
              gap: 1.5, 
              alignItems: 'center'
            }}>
              <TextField
                fullWidth
                placeholder="Search tenants..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: colors.teal, fontSize: 20 }} />
                    </InputAdornment>
                  ),
                  sx: {
                    height: 40,
                    padding: '0 12px',
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    bgcolor: 'white',
                    borderRadius: 2,
                    '& fieldset': {
                      borderColor: 'rgba(0, 0, 0, 0.1)',
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgba(0, 0, 0, 0.2)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.teal,
                    }
                  },
                  '& .MuiInputBase-input': {
                    padding: '8px 0',
                    fontSize: '0.875rem',
                  }
                }}
              />
              <IconButton 
                onClick={handleFilterClick}
                sx={{ 
                  bgcolor: filterAnchorEl ? colors.teal : 'white',
                  width: 40,
                  height: 40,
                  borderRadius: '50%',
                  padding: 0,
                  boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                  '&:hover': { 
                    bgcolor: filterAnchorEl ? colors.darkteal : 'white' 
                  }
                }}
              >
                <FilterListIcon 
                  sx={{ 
                    color: filterAnchorEl ? 'white' : colors.teal,
                    fontSize: 20
                  }} 
                />
              </IconButton>
            </Box>
        </Box>
      </Container>
      </Box>

    {/* Active Filter Chip */}
    <Container disableGutters sx={{ px: 3 }}>
        {selectedFilter !== 'all' && (
          <Box sx={{ mb: 2 }}>
            <Chip
              label={filters.find(f => f.value === selectedFilter)?.label}
              onDelete={() => setSelectedFilter('all')}
              color="primary"
              sx={{ 
                bgcolor: `${colors.teal}15`,
                color: colors.teal,
                '& .MuiChip-deleteIcon': { color: colors.teal }
              }}
            />
          </Box>
        )}
       

      <Box sx={{ py: 2 }}>
      {/* Rooms List */}
        {filteredRooms.map((room) => (
          <Card
            key={room._id}
            component={Link}
            to={`/room-profile/${room._id}`}
            sx={{
              mb: 2,
              p: 2,
              display: 'flex',
              alignItems: 'center',
              borderRadius: 3,
              bgcolor: 'white',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              textDecoration: 'none',
              cursor: 'pointer',
              transition: 'all 0.2s',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
              }
            }}
          >
            <Box
              component="img"
              sx={{ 
                height: 30, 
                width: 30, 
                mr: 2,
                p: 1,
                bgcolor: `${colors.teal}15`,
                borderRadius: 2
              }}
              alt="Room Icon"
              src={`${process.env.PUBLIC_URL}/icons/blackrizzyicon.png`}
            />
            
            <Box sx={{ flex: 1 }}>
              <Typography variant="subtitle1" sx={{ 
                color: colors.primary, 
                fontWeight: 600,
                textTransform: 'capitalize'
              }}>
                {room.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {room.floor}
              </Typography>
            </Box>

            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="subtitle2" sx={{ 
                color: room.isAvailable ? colors.teal : '#FF4B4B',
                fontWeight: 600,
                mb: 0.5
              }}>
                {room.isAvailable ? 'Vacant' : 'Occupied'}
              </Typography>
              <Typography variant="body2" sx={{ 
                color: colors.primary,
                fontWeight: 500
              }}>
                ₹{room.rent}
              </Typography>
            </Box>

            <IconButton sx={{ ml: 1, color: colors.teal }}>
              <ChevronRight/>
            </IconButton>
          </Card>
        ))}

        {filteredRooms.length === 0 && (
          <Box sx={{ 
            textAlign: 'center', 
            mt: 8,
            p: 3,
            bgcolor: 'white',
            borderRadius: 3
          }}>
            <Typography color="text.secondary">
              No rooms found
            </Typography>
          </Box>
        )}
    </Box>
    </Container>


      {/* Filter Menu */}
      <Menu
        anchorEl={filterAnchorEl}
        open={Boolean(filterAnchorEl)}
        onClose={handleFilterClose}
        PaperProps={{
          sx: {
            mt: 1,
            borderRadius: 2,
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
          }
        }}
      >
        {filters.map((filter) => (
          <MenuItem
            key={filter.value}
            onClick={() => handleFilterSelect(filter.value)}
            selected={selectedFilter === filter.value}
          >
            {filter.label}
          </MenuItem>
        ))}
      </Menu>

      <BottomNavigationComponent />
    </Box>
  );
};

export default RoomList;