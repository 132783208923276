import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../utils/IP'; 
import { Container, Box, Typography, IconButton, TextField, Button, Paper, FormControl, InputLabel, Select, MenuItem, Snackbar } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import { fetchDues } from '../../features/propertySlice';
import colors from '../../styles/colors';

const RecordPayment = () => {
  const { tenantId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tenantName, setTenantName] = useState('');
  const [dueAmount, setDueAmount] = useState('');
  const [paymentAmount, setPaymentAmount] = useState('');
  const [collectionMode, setCollectionMode] = useState('');
  const [description, setDescription] = useState('');
  const [selectedDue, setSelectedDue] = useState('all');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const { properties, selectedProperty, loading, tenants, rooms, dues } = useSelector(state => state.property);

  const tenant = useMemo(() => tenants.find(t => t.tenant._id === tenantId), [tenants, tenantId]);
  const tenantDues = useMemo(() => 
    dues.filter(d => (d.tenant === tenantId || d.tenant?._id === tenantId) && d.status === 'Pending'),
    [dues, tenantId]
  );

  useEffect(() => {
    if (tenant) {
      setTenantName(tenant.tenant.name);
    }
    const totalDueAmount = tenantDues.reduce((acc, due) => acc + due.dueAmount, 0);
    setDueAmount(totalDueAmount);
    setPaymentAmount(totalDueAmount.toString());
  }, [tenant, tenantDues]);

  const handleDueSelectionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedDue(selectedValue);

    if (selectedValue === 'all') {
      const totalAmount = tenantDues.reduce((acc, due) => acc + due.dueAmount, 0);
      setPaymentAmount(totalAmount.toString());
    } else {
      const due = tenantDues.find(d => d._id === selectedValue);
      setPaymentAmount(due ? due.dueAmount.toString() : '0');
    }
  };

  const handleCollectionModeChange = (event) => {
    setCollectionMode(event.target.value);
  };

  const handlePaymentAmountChange = (event) => {
    setPaymentAmount(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleSubmitPayment = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
    };

    try {
      let response;
      if (selectedDue === 'all') {
        response = await axios.post(
          `${BASE_URL}/dues/record-payment/tenant/${tenantId}`,
          {
            amount: parseFloat(paymentAmount),
            paymentMode: collectionMode,
          },
          config
        );
      } else {
        response = await axios.post(
          `${BASE_URL}/dues/record-payment/due/${selectedDue}`,
          {
            amount: parseFloat(paymentAmount),
            paymentMode: collectionMode,
          },
          config
        );
      }
      console.log('Payment response:', response.data);
      if (selectedProperty) {
        dispatch(fetchDues(selectedProperty._id));
      }
      setSnackbarMessage('Payment recorded successfully');
      setSnackbarOpen(true);
      setTimeout(() => navigate(-1), 2000);
    } catch (error) {
      console.error('Error recording payment:', error);
      setSnackbarMessage(error.response?.data?.message || 'Error recording payment');
      setSnackbarOpen(true);
    }
  };

  return (
    <Container maxWidth="sm" disableGutters>
      <Box sx={{
        bgcolor: colors.secondary,
        pb: 2,
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
        mx: 0,
        px: 2,
        color: colors.primary,
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', pt: 2 }}>
          <IconButton onClick={handleBackClick} sx={{ color: colors.primary }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ fontSize: '1.2rem', textAlign: 'left', flexGrow: 1 }}>
            Record Payment
          </Typography>
        </Box>
      </Box>

      <Paper sx={{ mt: 2, p: 3, mx: 0  ,pl:4,pr:4,}}>
        <TextField
          fullWidth
          label="Tenant Name"
          variant="outlined"
          value={tenantName}
          disabled
          sx={{ mb: 2 }}
        />

        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <InputLabel id="due-selection-label">Select Due</InputLabel>
          <Select
            labelId="due-selection-label"
            value={selectedDue}
            onChange={handleDueSelectionChange}
            label="Select Due"
          >
            <MenuItem value="all">All Pending Dues</MenuItem>
            {tenantDues.map((due) => (
              <MenuItem key={due._id} value={due._id}>
                {due.dueType} (₹{due.dueAmount})
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Payment Amount"
          variant="outlined"
          value={paymentAmount}
          onChange={handlePaymentAmountChange}
          type="number"
          sx={{ mb: 2 }}
        />

        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <InputLabel id="collection-mode-label">Collection Mode</InputLabel>
          <Select
            labelId="collection-mode-label"
            value={collectionMode}
            onChange={handleCollectionModeChange}
            label="Collection Mode"
          >
            <MenuItem value="Cash">Cash</MenuItem>
            <MenuItem value="UPI">UPI</MenuItem>
            <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Description"
          variant="outlined"
          value={description}
          onChange={handleDescriptionChange}
          sx={{ mb: 2 }}
        />

        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ backgroundColor: '#1AA393', textTransform: 'none' }}
          onClick={handleSubmitPayment}
        >
          Submit Payment
        </Button>
      </Paper>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default RecordPayment;