import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Box, Typography, IconButton, TextField, Button, Paper, MenuItem, FormControl,
     InputLabel, Select, CircularProgress,Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,Menu,Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { updateDue } from '../../features/propertySlice';
import colors from '../../styles/colors';

const dueTypes = [
  { label: 'Rent', value: 'Rent' },
  { label: 'Electricity Bill', value: 'electricity' },
  { label: 'Water Bill', value: 'water' },
  { label: 'Maintenance', value: 'maintenance' },
];

const UpdateDue = () => {
  const { dueId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { selectedProperty } = useSelector(state => state.property);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');


  const [loading, setLoading] = useState(true);
  const [dueData, setDueData] = useState({
    dueAmount: '',
    totalDue: '',
    paymentAmount: 0,
    dueType: '',
    dueDate: '',
    remark: '',
    tenant: '',
    room: '',
    property: '',
    status: 'Pending',
  });

  useEffect(() => {
    if (location.state && location.state.dueData) {
      const initialDueData = location.state.dueData;
      setDueData(prevState => ({
        ...prevState,
        ...initialDueData,
        property: selectedProperty ? selectedProperty.name : '',
        dueDate: initialDueData.dueDate ? initialDueData.dueDate.split('T')[0] : '',
        dueAmount: initialDueData.dueAmount || '',
        totalDue: initialDueData.totalDue || initialDueData.dueAmount || '',
      }));
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [location.state, selectedProperty]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleUpdateTenant = () => {
    setDialogType('updateDue');
    setDialogMessage('Are you sure you want to update the Due details?');
    setDialogOpen(true);
  };

  const handleDialogConfirm = async () => {
    setDialogOpen(false);
    try {
        if (dialogType === 'updateDue'){
        await dispatch(updateDue({ dueId, dueData })).unwrap();
        setDialogMessage('Due updated successfully!');
        setDialogType('success');
        setDialogOpen(true);
        setTimeout(() => {
           navigate(-1, { 
               state: { activeTab: location.state?.previousTab ?? 0 }
             });
          setTimeout(() => setDialogOpen(false), 1000);
        }, 2000);
      }}  
      catch (error) {
      console.error(`Error updating due detail`, error);
      setDialogMessage(`Failed to update due details. ${error.message}`);
      setDialogType('error');
      setDialogOpen(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDueData(prevState => {
      const newState = { ...prevState, [name]: value };
      
      // Update totalDue when dueAmount changes
      if (name === 'dueAmount') {
        newState.totalDue = value;
      }
      
      return newState;
    });
  };

  const handleCloseDialog = () => { 
    setDialogOpen(false);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm" disableGutters>
      <Box sx={{ bgcolor: colors.secondary, // Primary color
          pb: 2,
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
          mx: 0, px: 2, color: colors.primary }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: 2 }}>
          <IconButton onClick={handleBackClick} sx={{ color: colors.primary}}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ fontSize: '1.2rem', textAlign: 'left', flexGrow: 1 }}>Update Due</Typography>
          <Box sx={{ width: 24 }} />
        </Box>
      </Box>

      <Paper sx={{ mt: 2, p: 2 }}>
        <Box sx={{ mt: 0 }}>
          <Box sx={{ p: 0, ml: 1, mb:4 }}>
                <Grid container spacing={2} sx={{ mt: 1, wordBreak: 'break-word' }}>
                  <Grid item xs={5} >
                    <Typography variant="body2" color="textSecondary">Tenant Name :</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body1">{(dueData.tenant && dueData.tenant.name) || ''}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body2" color="textSecondary">Room Number :</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body1">{(dueData.room && dueData.room.name) || ''}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body2" color="textSecondary">Property Name :</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body1">{dueData.property || ''}</Typography>
                  </Grid>
                  
                </Grid>
                
              </Box>
          
          <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
            <InputLabel id="due-type-label">Due Type</InputLabel>
            <Select
              labelId="due-type-label"
              value={dueData.dueType || ''}
              name="dueType"
              onChange={handleChange}
              label="Due Type"
            >
              {dueTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Due Amount"
            variant="outlined"
            sx={{ mb: 2 }}
            name="dueAmount"
            value={dueData.dueAmount || ''}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Due Date"
            variant="outlined"
            type="date"
            sx={{ mb: 2 }}
            InputLabelProps={{
              shrink: true,
            }}
            name="dueDate"
            value={dueData.dueDate || ''}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Remark"
            variant="outlined"
            multiline
            rows={4}
            sx={{ mb: 2 }}
            name="remark"
            value={dueData.remark || ''}
            onChange={handleChange}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ backgroundColor: '#1AA393', textTransform: 'none' }}
            onClick={handleUpdateTenant}
          >
            Update Due
          </Button>
        </Box>
      </Paper>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogType === 'updateDue'  ? "Confirm Action" : 
           dialogType === 'success' ? "Success" : "Error"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {(dialogType === 'updateDue' ) ? (
            <>
              <Button onClick={handleCloseDialog}>Cancel</Button>
              <Button onClick={handleDialogConfirm} autoFocus>
                Confirm
              </Button>
            </>
          ) : (
            <Button onClick={handleCloseDialog} autoFocus>
              OK
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default UpdateDue;