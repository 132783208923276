import React, { useState, useContext, useEffect,useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Box,
  Typography,
  Grid,
  ButtonBase,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Card,
  CardContent,
  MenuItem,
  Select,
  FormControl,
  Tooltip,
  Fade,
  Grow,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import RoomIcon from '@mui/icons-material/Room';
import AddIcon from '@mui/icons-material/PersonAdd';
import  DoorFront from '@mui/icons-material';
import PaymentIcon from '@mui/icons-material/Payment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import BottomNavigationComponent from '../others/BottomNavigationComponent';
import { AuthContext } from '../../contexts/AuthContext';
import {jwtDecode} from 'jwt-decode'; // Correct import
import axios from 'axios';
import { PropertyContext } from '../../contexts/PropertyContext';
import { styled } from '@mui/system';
import { selectPaidDues,fetchProperties, setSelectedProperty, clearProperties,selectShouldFetchPropertyData, fetchAllPropertyData,selectTotalPendingDue,selectTotalCollection,selectTotalExpense } from '../../features/propertySlice';
import { logout } from '../../features/authSlice';
import { BASE_URL } from '../../utils/IP'; 
import colors from '../../styles/colors';
import {Apartment,DoorFrontSharp,Room} from '@mui/icons-material';
import AssignmentLate from '@mui/icons-material/AssignmentLate';
import { KeyboardArrowRight } from '@mui/icons-material';
import LandlordDashboard from '../money/MoneyDashboard';







const CustomDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#D0E5E5',
  },
}));

const BottomCustomDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#D0E5E5',
  },
}));


const Home = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState('currentMonth');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAuthenticated, isLoading, user } = useSelector(state => state.auth);
  const { properties, selectedProperty, loading, tenants, rooms, dues, complaints } = useSelector(state => state.property);

  const totalPendingDue = useSelector(selectTotalPendingDue);
  const totalCollection = useSelector(selectTotalCollection);
  const totalExpense = useSelector(selectTotalExpense);
  const paidDues = useSelector(selectPaidDues);


  const totalRooms = rooms.length;
  const vacantRooms = rooms.filter(room => room.isAvailable).length;

  const onNoticeTenantCount = useMemo(() => {
    return tenants.filter(t => t.status === 'OnNotice').length;
  }, [tenants]);



  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const shouldFetchPropertyData = useSelector(selectShouldFetchPropertyData);

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading && isAuthenticated && user) {
        if (properties.length === 0) {
          await dispatch(fetchProperties(user.id));
        }
        
        if (shouldFetchPropertyData) {
          await dispatch(fetchAllPropertyData(selectedProperty._id));
        }
      } else if (!isAuthenticated && !isLoading) {
        navigate('/login');
      }
    };

    fetchData();
  }, [isLoading, isAuthenticated, user, dispatch, navigate, properties.length, selectedProperty, shouldFetchPropertyData]);


  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleSideDrawerOpen = () => {
    setSideDrawerOpen(true);
  };

  const handleSideDrawerClose = () => {
    setSideDrawerOpen(false);
  };

  const handlePropertySelect = (property) => {
    dispatch(setSelectedProperty(property));
    handleDrawerClose();
  };


  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearProperties());
    navigate('/login');
  };

  const handleNavigateToAddDue = () => {
    navigate("/tenants");
  };

  const handleNavigateToDuesTab = () => {
    navigate("/Rent-Due-Collection", { state: { activeTab: 1 } });
  };

  const handleNavigateToComplaintList = () => {
    navigate("/complaints");
  };

  const handleNavigateToRoomList = () => {
    navigate("/rooms");
  };

  const handleNavigateToTenantList = () => {
    navigate("/tenants");
  };

  const handleAutoDueManual = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          //'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };
      await axios.post(`${BASE_URL}/trigger-rent-dues/trigger-rent-dues`,null, config);
      console.log('auto due manually running')
    } catch (error) {
      console.error('Error adding due:', error);
    }
  };

  return (
    <Container sx={{ pb: 8 }}> {/* Added padding-bottom */}
      <Box
        sx={{
          bgcolor: colors.secondary,
          p: 2,
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          color: colors.primary,
          display: 'flex',
          alignItems: 'center',
          mx: -2,
          px: 3,
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.07)'
        }}
      >
        <Tooltip title="Menu" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
          <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleSideDrawerOpen}>
            <MenuIcon />
          </IconButton>
        </Tooltip>
        <Typography variant="h6" sx={{fontWeight:600 ,color:colors.primary }}>
        {loading.properties ? 'Loading Properties...' : selectedProperty ? selectedProperty.name : 'No Property Selected'}
        </Typography>
        <IconButton edge="end" color="inherit" aria-label="expand" onClick={handleDrawerOpen}>
          <ExpandMoreIcon />
        </IconButton>
      </Box>
      <BottomCustomDrawer anchor="bottom" open={drawerOpen} onClose={handleDrawerClose} >
        <Box sx={{ p: 1, width: 'auto' }}>
          <List>
          {loading.properties ? (
              <ListItem>
                <ListItemText primary="Loading properties..." />
              </ListItem>
            ) : properties.length === 0 ? (
              <ListItem>
                <ListItemText primary="No properties found" />
              </ListItem>
            ) : (
              properties.map((property, index) => (
              <ListItem button key={index} onClick={() => handlePropertySelect(property)}
                sx={{
                  animation: selectedProperty === property._id ? 'fadeIn 0.5s' : 'none',
                  backgroundColor: selectedProperty === property._id ? '#f0f0f0' : 'inherit',
                  '@keyframes fadeIn': {
                    from: { opacity: 0 },
                    to: { opacity: 1 },
                  },
                }}>
                <ListItemIcon>
                  <Room sx={{color:colors.darkteal,}}/>
                </ListItemIcon>
                <ListItemText primary={property.name} />
              </ListItem>
            ))
          )}
          </List>
          <Divider />
          <ListItem button component={Link} to="/add-property">
            <ListItemText primary="+ Add Property" />
          </ListItem>
        </Box>
      </BottomCustomDrawer>
      <CustomDrawer  anchor="left" open={sideDrawerOpen} onClose={handleSideDrawerClose}>
        <Box sx={{ width: 250 , }}>
          <List>
            <ListItem>
              <ListItemText primary={`Welcome, ${user ? user.username : ''}`} />
            </ListItem>
            <Divider />
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
            <ListItem button onClick={handleNavigateToRoomList}>
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              <ListItemText primary="Rooms Section" />
            </ListItem>
            <ListItem button onClick={handleNavigateToTenantList}>
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              <ListItemText primary="Tenant Section" />
            </ListItem>
          </List>
            <ListItem button onClick={handleNavigateToDuesTab}>
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              <ListItemText primary="Due Section" />
            </ListItem>
            <ListItem button onClick={handleNavigateToComplaintList}>
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              <ListItemText primary="Complaint Section" />
            </ListItem>
            
        </Box>
      </CustomDrawer>

      <Box sx={{ mt: 4 }}>

        <LandlordDashboard
          selectedPeriod={selectedPeriod}
          totalCollection={totalCollection}
          totalPendingDue={totalPendingDue}
          collections={paidDues}
        />
      <Box sx={{m:2,mt:-5}}>
        <Typography sx={{ color: colors.darkteal, mt: 0 , fontWeight: 'bold' , mt: 3}}>
                     Quick Links
        </Typography>
        <Grid container spacing={3} sx={{ mt: 0 }}>
          <Grid item xs={3}>
            <ButtonBase
                component={Link}
                to="/add-tenant"
                sx={{ width: 80, height: 80, bgcolor: colors.secondary, borderRadius: '20%' ,boxShadow: '0 0px 10px rgba(0, 0, 0, 0.07)'}}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <AddIcon sx={{ fontSize: 25, color: colors.primary }} />
                  <Typography sx={{ color: colors.primary, fontSize: 12 }}>Add Tenant</Typography>
                </Box>
              </ButtonBase>
          </Grid>
          <Grid item xs={3}>
            <ButtonBase
              component={Link}
              to="/add-room"
              sx={{  width: 80, height: 80, bgcolor: colors.secondary, borderRadius: '20%',boxShadow: '0 0px 10px rgba(0, 0, 0, 0.07)' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <DoorFrontSharp sx={{ fontSize: 25, color: colors.primary }} />
                <Typography sx={{ color: colors.primary,fontSize: 12 }}>Add Room</Typography>
              </Box>
            </ButtonBase>
          </Grid>
          <Grid item xs={3}>
            <ButtonBase
              onClick={handleNavigateToAddDue}
              sx={{  width: 80, height: 80, bgcolor: colors.secondary, borderRadius: '20%', boxShadow: '0 0px 10px rgba(0, 0, 0, 0.07)' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <AssignmentIcon sx={{ fontSize: 25, color: colors.primary }} />
                <Typography sx={{ color: colors.primary, fontSize: 12}}>Add Due</Typography>
              </Box>
            </ButtonBase>
          </Grid>
          <Grid item xs={3}>
            <ButtonBase
              onClick={handleNavigateToDuesTab}
              sx={{  width: 80, height: 80, bgcolor: colors.secondary, borderRadius: '20%',boxShadow: '0 0px 10px rgba(0, 0, 0, 0.07)' }} //D4D8F0
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <PaymentIcon sx={{ fontSize: 25, color: colors.primary }} /> 
                <Typography sx={{ color: colors.primary, fontSize: 12 }}>Record Payment</Typography>
              </Box>
            </ButtonBase>
          </Grid>
          <Grid item xs={3}>
            <ButtonBase
              onClick={handleNavigateToComplaintList}
              sx={{  width: 80, height: 80, bgcolor: colors.secondary, borderRadius: '20%',boxShadow: '0 0px 10px rgba(0, 0, 0, 0.07)' }} //D4D8F0
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <PaymentIcon sx={{ fontSize: 25, color: colors.primary }} /> 
                <Typography sx={{ color: colors.primary, fontSize: 12 }}>All Complaints</Typography>
              </Box>
            </ButtonBase>
          </Grid>
          <Grid item xs={3}>
            <ButtonBase
              onClick={handleAutoDueManual}
              sx={{  width: 80, height: 80, bgcolor: colors.secondary, borderRadius: '20%',boxShadow: '0 0px 10px rgba(0, 0, 0, 0.07)' }} //D4D8F0
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <PaymentIcon sx={{ fontSize: 25, color: colors.primary }} /> 
                <Typography sx={{ color: colors.primary, fontSize: 12 }}>auto due trigger</Typography>
              </Box>
            </ButtonBase>
          </Grid>
        </Grid>
        </Box>
        </Box>
      <BottomNavigationComponent />
    </Container>
  );
};

export default Home;
