import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../utils/IP';
import {
  Container, Box, Typography, TextField, Button, Paper,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  FormControl, InputLabel, Select, MenuItem, IconButton, Tooltip,
  Dialog, DialogTitle, DialogContent, DialogActions, Alert,CircularProgress,Grid,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { fetchRooms, fetchTenants } from '../../features/propertySlice';
import axios from 'axios';
import colors from '../../styles/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const ElectricityBillCalculator = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [billData, setBillData] = useState({
    billingMonth: '',
    perUnitRate: '',
    minimumCharge: '',
    additionalCharges: '',
  });

  const [roomReadings, setRoomReadings] = useState([]);
  const [calculatedBills, setCalculatedBills] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [error, setError] = useState('');
  const [initializing, setInitializing] = useState(true);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);


  const { rooms, tenants, selectedProperty } = useSelector(state => state.property);


    // Initialize room readings when component loads
    useEffect(() => {
        if (rooms && rooms.length > 0 && tenants) {
          const initialReadings = rooms.map(room => {
            // Get active tenants for this room from the existing tenants data
            const roomTenants = tenants.filter(t => 
              t.room?._id === room._id && 
              t.status !== 'Exited' &&
              t.tenant
            );
    
            return {
              roomId: room._id,
              roomName: room.name,
              previousReading: room.lastAddedReading || 0,
              currentReading: '',
              unitsConsumed: 0,
              billAmount: 0,
              tenants: roomTenants,
              tenantsCount: roomTenants.length
            };
          });
          setRoomReadings(initialReadings);
          setInitializing(false);
        }
      }, [rooms, tenants]);
    
      // Show loading only during initial data setup
      if (initializing) {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </Box>
        );
      }
    
      // Validate if we have the necessary data
      if (!selectedProperty || !rooms || rooms.length === 0) {
        return (
          <Container>
            <Box sx={{ mt: 4, textAlign: 'center' }}>
              <Typography variant="h6" color="error">
                No property or room data available.
              </Typography>
              <Button 
                variant="contained" 
                onClick={() => navigate(-1)}
                sx={{ mt: 2 }}
              >
                Go Back
              </Button>
            </Box>
          </Container>
        );
      }

  const handleInputChange = (e) => {
    setBillData({
      ...billData,
      [e.target.name]: e.target.value
    });
  };

  const handleReadingChange = (roomId, value) => {
    const updatedReadings = roomReadings.map(reading => {
      if (reading.roomId === roomId) {
        const currentReading = parseFloat(value) || 0;
        const previousReading = parseFloat(reading.previousReading) || 0;
        const unitsConsumed = Math.max(currentReading - previousReading, 0);
        
        return {
          ...reading,
          currentReading: value,
          unitsConsumed
        };
      }
      return reading;
    });
    setRoomReadings(updatedReadings);
  };

  
  const calculateBills = () => {
    if (!billData.billingMonth || !billData.perUnitRate) {
      setError('Please fill in all required fields');
      return;
    }
  
    const perUnitRate = parseFloat(billData.perUnitRate) || 0;
    const minimumCharge = parseFloat(billData.minimumCharge) || 0;
    const additionalCharges = parseFloat(billData.additionalCharges) || 0;
  
    const calculated = roomReadings.map(reading => {
      const basicCharge = parseFloat((reading.unitsConsumed * perUnitRate).toFixed(2));
      const totalBill = parseFloat((Math.max(basicCharge, minimumCharge) + additionalCharges).toFixed(2));
  
      // Calculate per tenant share if there are active tenants
      const perTenantBill = reading.tenantsCount > 0 ? 
        parseFloat((totalBill / reading.tenantsCount).toFixed(2)) : 
        totalBill;
  
      return {
        ...reading,
        basicCharge,
        totalBill,
        perTenantBill,
        billsToGenerate: reading.tenants.map(t => ({
          tenantId: t.tenant._id,
          tenantName: t.tenant.name,
          amount: perTenantBill
        }))
      };
    });
  
    setCalculatedBills(calculated);
    setShowConfirm(true);
  };

  const generateBills = async () => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };
  
      // First create all dues
      const duePromises = calculatedBills.flatMap(bill => {
        if (bill.unitsConsumed <= 0) return [];
  
        return bill.billsToGenerate.map(async tenantBill => {
          const dueData = {
            dueAmount: tenantBill.amount,
            dueType: 'electricity',
            dueDate: new Date(),
            room: bill.roomId,
            tenant: tenantBill.tenantId,
            status: 'Pending',
            totalDue: tenantBill.amount,
            paymentAmount: 0,
            description: `Electricity bill for ${billData.billingMonth} - Units: ${bill.unitsConsumed} (Split among ${bill.tenantsCount} tenants)`,
            isRent: false
          };
  
          return axios.post(`${BASE_URL}/dues`, dueData, config);
        });
      });
  
      await Promise.all(duePromises);
  
      // Then update room readings using the new endpoint
      const roomPromises = calculatedBills.map(bill => {
        if (bill.unitsConsumed > 0) {
          return axios.put(
            `${BASE_URL}/rooms/${bill.roomId}/reading`,
            {
              lastAddedReading: parseFloat(bill.currentReading),
              readingDate: new Date()
            },
            config
          );
        }
        return Promise.resolve();
      });
  
      await Promise.all(roomPromises);
      
      setShowConfirm(false);
      setShowSuccessDialog(true); // Show success dialog
      // Navigate after a delay
    setTimeout(() => {
        setShowSuccessDialog(false);
        navigate('/rent-due-collection');
      }, 4000);
    } catch (error) {
      console.error('Error generating bills:', error);
      setError(error.response?.data?.message || 'Failed to generate bills. Please try again.');
    }
  };

  

  return (
    <Container maxWidth="md" sx={{ pb: 4 }}>
      <Box sx={{
        bgcolor: colors.secondary,
        pb: 2,
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
        mx: -2,
        px: 2,
        color: colors.primary,
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', pt: 2 }}>
          <IconButton onClick={() => navigate(-1)} sx={{ color: colors.primary }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">Generate Electricity Bills</Typography>
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mt: 2 }} onClose={() => setError('')}>
          {error}
        </Alert>
      )}

      <Paper sx={{ mt: 3, p: 3 }}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Billing Parameters</Typography>
          
          <FormControl fullWidth sx={{ mb: 2 }} required>
            <InputLabel>Billing Month</InputLabel>
            <Select
              name="billingMonth"
              value={billData.billingMonth}
              onChange={handleInputChange}
              label="Billing Month"
            >
              {['January', 'February', 'March', 'April', 'May', 'June', 
                'July', 'August', 'September', 'October', 'November', 'December']
                .map(month => (
                  <MenuItem key={month} value={month}>{month}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            required
            label="Per Unit Rate (₹)"
            name="perUnitRate"
            type="number"
            value={billData.perUnitRate}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />

          <TextField
            fullWidth
            label="Minimum Charges (₹)"
            name="minimumCharge"
            type="number"
            value={billData.minimumCharge}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />

          <TextField
            fullWidth
            label="Additional Charges (₹)"
            name="additionalCharges"
            type="number"
            value={billData.additionalCharges}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Room</TableCell>
                <TableCell>Previous Reading</TableCell>
                <TableCell>Current Reading</TableCell>
                <TableCell>Units Consumed</TableCell>
                <TableCell>Active Tenants</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roomReadings.map((reading) => (
                <TableRow key={reading.roomId}>
                  <TableCell>{reading.roomName}</TableCell>
                  <TableCell>{reading.previousReading}</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={reading.currentReading}
                      onChange={(e) => handleReadingChange(reading.roomId, e.target.value)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>{reading.unitsConsumed}</TableCell>
                  <TableCell>
                    {reading.tenantsCount} 
                    <Tooltip title={reading.tenants.map(t => t.tenant.name).join(', ')}>
                      <InfoIcon sx={{ ml: 1, fontSize: 16, cursor: 'pointer' }} />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Button
          variant="contained"
          onClick={calculateBills}
          sx={{ mt: 3, bgcolor: colors.teal }}
          fullWidth
        >
          Calculate Bills
        </Button>
      </Paper>

      <Dialog open={showConfirm} onClose={() => setShowConfirm(false)} maxWidth="md" fullWidth>
        <DialogTitle>Confirm Bill Generation</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Room</TableCell>
                  <TableCell>Units</TableCell>
                  <TableCell>Total Bill</TableCell>
                  <TableCell>Tenants</TableCell>
                  <TableCell>Per Tenant Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {calculatedBills.map((bill) => (
                  <TableRow key={bill.roomId}>
                    <TableCell>{bill.roomName}</TableCell>
                    <TableCell>{bill.unitsConsumed}</TableCell>
                    <TableCell>₹{bill.totalBill?.toFixed(2)}</TableCell>
                    <TableCell>{bill.tenantsCount}</TableCell>
                    <TableCell>₹{bill.perTenantBill?.toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirm(false)}>Cancel</Button>
          <Button onClick={generateBills} variant="contained" color="primary">
            Generate Bills
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog 
  open={showSuccessDialog} 
  onClose={() => setShowSuccessDialog(false)}
  maxWidth="sm"
  fullWidth
>
  <DialogContent sx={{ 
    display: 'flex', 
    flexDirection: 'column',
    alignItems: 'center', 
    padding: 3,
    bgcolor: colors.secondary 
  }}>
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center',
      gap: 2,
      width: '100%'
    }}>
      <CheckCircleIcon sx={{ fontSize: 60, color: colors.teal }} />
      <Typography variant="h6" textAlign="center">
        Bills Generated Successfully!
      </Typography>
      
      <Box sx={{ width: '100%', mt: 2 }}>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Summary:
        </Typography>
        <Box sx={{ 
          bgcolor: 'background.paper', 
          p: 2, 
          borderRadius: 1,
          width: '100%'
        }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Total Bills Generated:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                {calculatedBills.reduce((sum, bill) => 
                  sum + bill.billsToGenerate.length, 0
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Total Amount:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                ₹{calculatedBills.reduce((sum, bill) => 
                  sum + bill.totalBill, 0
                ).toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
        Redirecting to dues page...
      </Typography>
    </Box>
  </DialogContent>
</Dialog>
    </Container>
  );
};

export default ElectricityBillCalculator;