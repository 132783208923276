import React, { useState } from 'react';
import { 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  Badge,
  IconButton,
  Modal,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import { KeyboardArrowRight } from '@mui/icons-material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TimelineIcon from '@mui/icons-material/Timeline';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import CloseIcon from '@mui/icons-material/Close';
import colors from '../../styles/colors';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const LandlordDashboard = ({ 
  totalCollection, 
  totalPendingDue,
  collections = []
}) => {
  const navigate = useNavigate();
  const { dues, tenants, rooms } = useSelector(state => state.property);
  const [yearlyOverviewOpen, setYearlyOverviewOpen] = useState(false);
  
  const underVerificationCount = dues.filter(due => due.status === 'UnderVerification').length;
  const occupiedRooms = rooms.filter(room => room.currentOcc > 0).length;
  const totalRooms = rooms.length;
  const tenantsUnderNotice = tenants.filter(tenant => tenant.status === 'OnNotice').length;
  const totalSecurityDeposit = tenants.reduce((sum, tenant) => sum + (tenant.securityDeposit || 0), 0);

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  // Calculate collections for each month
  const monthlyCollections = months.slice(0, currentMonth + 1).map(month => {
    const monthIndex = months.indexOf(month);
    const monthCollections = collections.filter(collection => {
      const collectionDate = new Date(collection.paymentDateTime);
      return collectionDate.getMonth() === monthIndex && 
             collectionDate.getFullYear() === currentYear;
    });
    const total = monthCollections.reduce((sum, collection) => 
      sum + collection.paymentAmount, 0
    );
    return { month, total, count: monthCollections.length };
  });

  // Get current month collections
  const thisMonthCollections = collections.filter(collection => {
    const collectionDate = new Date(collection.paymentDateTime);
    return collectionDate.getMonth() === currentMonth;
  });
  const thisMonthTotal = thisMonthCollections.reduce((sum, collection) => 
    sum + collection.paymentAmount, 0
  );

  const MainStatCard = ({ icon: Icon, title, value, subtext, color, onClick }) => (
    <Card 
      onClick={onClick}
      sx={{ 
        mb: 2.5,
        borderRadius: 3,
        bgcolor: 'white',
        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
        transition: 'all 0.3s ease',
        transform: 'translateY(0)',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
        }
      }}
    >
      <CardContent sx={{ p: 2.5 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ 
            width: 48,
            height: 48,
            borderRadius: 3,
            bgcolor: `${color}15`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 2,
            transition: 'transform 0.2s ease',
            '&:hover': {
              transform: 'scale(1.05)',
            }
          }}>
            <Icon sx={{ color: color, fontSize: 24 }} />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
              {title}
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 700, color: color }}>
              {value}
            </Typography>
          </Box>
        </Box>
        {subtext && (
          <Typography variant="caption" sx={{ 
            display: 'block',
            color: 'text.secondary',
            mt: 1.5,
            ml: 8
          }}>
            {subtext}
          </Typography>
        )}
      </CardContent>
    </Card>
  );

  const InfoCard = ({ title, value, color, onClick }) => (
    <Card 
      onClick={onClick}
      sx={{ 
        flex: 1,
        borderRadius: 3,
        bgcolor: 'white',
        boxShadow: '0 4px 12px rgba(0,0,0,0.06)',
        mx: 1,
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        '&:hover': {
          transform: 'translateY(-3px)',
          boxShadow: '0 6px 16px rgba(0,0,0,0.1)',
        },
        '&:first-of-type': { ml: 0 },
        '&:last-of-type': { mr: 0 },
        overflow: 'hidden',
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '3px',
          background: `linear-gradient(to right, ${color}, ${colors.teal})`,
        }
      }}
    >
      <CardContent sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="h6" sx={{ 
          fontWeight: 700, 
          color: colors.primary,
          mb: 0.5,
          fontSize: '1.1rem'
        }}>
          {value}
        </Typography>
        <Typography 
          variant="caption" 
          sx={{
            color: 'text.secondary',
            fontSize: '0.75rem',
            display: 'block'
          }}
        >
          {title}
        </Typography>
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ pb: 5 }}>
      
      {/* Mobile-Optimized Payment Verification Section */}
{underVerificationCount > 0 && (
  <Box sx={{ 
    mx: 2, 
    mb: 3,
    position: 'relative'
  }}>
    <Box
      onClick={() => navigate('/verification-requests')}
      sx={{
        borderRadius: 3,
        background: `linear-gradient(110deg, ${colors.teal} 0%, ${colors.darkteal} 100%)`,
        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
        overflow: 'hidden',
        transition: 'transform 0.2s ease',
        '&:active': {
          transform: 'scale(0.98)'
        }
      }}
    >
      <Box sx={{ 
        p: 2,
        display: 'flex',
        alignItems: 'center',
        gap: 2
      }}>
        <Badge
          badgeContent={underVerificationCount}
          color="error"
          sx={{
            '& .MuiBadge-badge': {
              bgcolor: '#FF4B4B',
              color: 'white',
              fontWeight: 'bold'
            }
          }}
        >
          <Box sx={{
            width: 42,
            height: 42,
            borderRadius: '12px',
            bgcolor: 'rgba(255,255,255,0.2)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <AssignmentLateIcon sx={{ color: 'white', fontSize: 24 }} />
          </Box>
        </Badge>

        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
            <Typography sx={{ 
              fontSize: '0.9rem',
              fontWeight: 600,
              color: 'white'
            }}>
              Verification Pending
            </Typography>
            <Box sx={{ 
              px: 1,
              py: 0.25,
              bgcolor: 'rgba(255,255,255,0.2)',
              borderRadius: 1,
              fontSize: '0.7rem',
              color: 'white'
            }}>
              NEW
            </Box>
          </Box>
          <Typography sx={{ 
            fontSize: '0.75rem',
            color: 'rgba(255,255,255,0.85)',
          }}>
            {underVerificationCount} payment{underVerificationCount > 1 ? 's' : ''} to verify
          </Typography>
        </Box>

        <Box sx={{ 
          display: 'flex',
          alignItems: 'center',
          color: 'white'
        }}>
          <KeyboardArrowRight sx={{ fontSize: 20 }} />
        </Box>
      </Box>
    </Box>
  </Box>
)}

    {/* Header with Month and Overview */}
    <Box sx={{ 
        px: 2, 
        py: 2, 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ 
            p: 1, 
            borderRadius: 2, 
            bgcolor: colors.lightTeal,
            mr: 1.5,
            display: 'flex'
          }}>
            <CalendarTodayIcon sx={{ color: colors.teal }} />
          </Box>
          <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.primary }}>
            {months[currentMonth]} {currentYear}
          </Typography>
        </Box>

        <IconButton 
          onClick={() => setYearlyOverviewOpen(true)}
          sx={{ 
            bgcolor: colors.lightTeal,
            '&:hover': { bgcolor: colors.lightTeal }
          }}
        >
          <TimelineIcon sx={{ color: colors.teal }} />
        </IconButton>
      </Box>

      {/* Main Stats */}
      <Box sx={{ px: 2 }}>
        <MainStatCard
          icon={CurrencyRupeeIcon}
          title={`${months[currentMonth]} Collection`}
          value={`₹${thisMonthTotal.toLocaleString()}`}
          subtext={`${thisMonthCollections.length} payments received`}
          color={colors.teal}
          onClick={() => navigate("/Rent-Due-Collection", { state: { activeTab: 2 } })}
        />

        <MainStatCard
          icon={PersonIcon}
          title="Pending Dues"
          value={`₹${totalPendingDue.toLocaleString()}`}
          subtext={`Pending payments to collect`}
          color="#FF6B6B"
          onClick={() => navigate("/Rent-Due-Collection", { state: { activeTab: 1 } })}
        />

        <MainStatCard
          icon={HomeIcon}
          title="Room Occupancy"
          value={`${occupiedRooms}/${totalRooms} Occupied`}
          subtext={`${totalRooms - occupiedRooms} rooms available`}
          color="#9C89FF"
          onClick={() => navigate('/rooms')}
        />

        {/* Quick Overview */}
        <Typography 
          variant="subtitle2" 
          sx={{ 
            mt: 3, 
            mb: 2, 
            ml: 1,
            color: colors.primary,
            fontWeight: 600,
            letterSpacing: '0.5px'
          }}
        >
          Quick Overview
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <InfoCard
            title="Active Tenants"
            value={tenants.length}
            color={colors.teal}
            onClick={() => navigate('/tenants')}
          />
          <InfoCard
            title="Security Deposit"
            value={`₹${(totalSecurityDeposit/1000).toFixed(0)}K`}
            color="#FF6B6B"
            onClick={() => navigate('/deposits')}
          />
          <InfoCard
            title="Under Notice"
            value={tenantsUnderNotice}
            color="#4ECDC4"
            onClick={() => navigate('/tenants?status=notice')}
          />
        </Box>
      </Box>

      {/* Yearly Overview Modal */}
      <Modal
        open={yearlyOverviewOpen}
        onClose={() => setYearlyOverviewOpen(false)}
        aria-labelledby="yearly-overview-modal"
      >
        <Paper sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 400,
          maxHeight: '80vh',
          overflow: 'auto',
          borderRadius: 3,
          p: 3,
          bgcolor: 'background.paper',
          boxShadow: 24,
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 600, color: colors.primary }}>
              {currentYear} Collections
            </Typography>
            <IconButton onClick={() => setYearlyOverviewOpen(false)} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
          <List>
            {monthlyCollections.reverse().map(({ month, total, count }, index) => (
              <React.Fragment key={month}>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" sx={{ color: colors.primary, fontWeight: 500 }}>
                        {month}
                      </Typography>
                    }
                    secondary={`${count} payments`}
                  />
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.teal }}>
                    ₹{total.toLocaleString()}
                  </Typography>
                </ListItem>
                {index < monthlyCollections.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </Modal>
    </Box>
  );
};

export default LandlordDashboard;