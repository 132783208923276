import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './features/authSlice';
import propertyReducer from './features/propertySlice';
import websocketMiddleware from './middleware/websocketMiddleware';

const rootReducer = combineReducers({
  auth: authReducer,
  property: propertyReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);


export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(websocketMiddleware),
});


export const persistor = persistStore(store, null, () => {
  // This callback runs after rehydration is complete
  store.dispatch({ type: 'REHYDRATION_COMPLETE' });
});