import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../utils/IP'; 
import { Container, Box, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Button, IconButton ,CircularProgress} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { PropertyContext } from '../../contexts/PropertyContext';
import axios from 'axios';
import { fetchTenants } from '../../features/propertySlice';
import colors from '../../styles/colors';


const EditTenant = () => {
  const { tenantId } = useParams(); // Get tenantId from URL parameters
  const [tenantData, setTenantData] = useState({
    name: '',
    phone: '',
    property: '',
    room: '',
    referredBy: '',
    moveIn: null,
    moveOut: null,
    lockInPeriod: '',
    noticePeriod: '',
    autoRentAddition: '1st of month',
    fixedRent: '',
    securityDeposit: '',
    rentDue: '',
    securityDepositDue: '',
  });

  const [error, setError] = useState('');
  const [localLoading, setLocalLoading] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { properties, selectedProperty, loading, tenants, rooms, dues, complaints } = useSelector(state => state.property);

  useEffect(() => {
    if (tenants && tenantId) {
      const fetchedTenant = tenants.find(t => t.tenant._id === tenantId);
      console.log('fetched tenant',fetchedTenant)
      if (fetchedTenant) {
        setTenantData({
          ...fetchedTenant,
          moveIn: fetchedTenant.moveIn ? new Date(fetchedTenant.moveIn) : null,
          moveOut: fetchedTenant.moveOut ? new Date(fetchedTenant.moveOut) : null,
          name: fetchedTenant.tenant.name || '',
          phone: fetchedTenant.tenant.phone || '',
          property: fetchedTenant.property._id || '',
          room: fetchedTenant.room._id || '',
          referredBy: fetchedTenant.referredBy || '',
          lockInPeriod: fetchedTenant.lockInPeriod || '',
          noticePeriod: fetchedTenant.noticePeriod || '',
          autoRentAddition: fetchedTenant.autoRentAddition || '1st of month',
          fixedRent: fetchedTenant.fixedRent || '',
          securityDeposit: fetchedTenant.securityDeposit || '',
          rentDue: fetchedTenant.rentDue || '',
          securityDepositDue: fetchedTenant.securityDepositDue || '',
        });
        setLocalLoading(false);
      } else {
        setLocalLoading(false);
        setError('Tenant not found');
      }
    } else if (!loading) {
      setLocalLoading(false);
    }
  }, [tenants, tenantId, loading]);

  if (localLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  const handleChange = (e) => {
    setTenantData({ ...tenantData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (date, field) => {
    setTenantData((prevData) => ({
      ...prevData,
      [field]: date,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };
      const requestData = {
        ...tenantData,
        moveIn: tenantData.moveIn ? tenantData.moveIn.toISOString() : null,
        moveOut: tenantData.moveOut ? tenantData.moveOut.toISOString() : null,
      };
      console.log('Sending PUT request to:', `${BASE_URL}/tenants/${tenantId}`);
      console.log('Request data:', requestData);
      await axios.put(`${BASE_URL}/tenants/${tenantId}`, requestData, config);

      if (tenantData.property) {
        await dispatch(fetchTenants(tenantData.property));
      }
      navigate('/tenant-profile/' + tenantId);
    } catch (error) {
      console.error('Error updating tenant:', error);
      setError('Error updating tenant');
    }
  };

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous screen
  };

  return (
    <Container maxWidth="sm" disableGutters sx={{ pb: 8 }}>
      <Box
        sx={{
          bgcolor: colors.secondary, // Primary color
          pb: 2,
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
          mx: 0,
          px: 2,
          color: colors.primary,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', pt: 2 }}>
          <IconButton onClick={handleBackClick} sx={{ color: colors.primary }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ fontSize: '1.2rem', textAlign: 'left', flexGrow: 1 }}>
            Edit Tenant
          </Typography>
        </Box>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box sx={{ mb: 3, mt: 2, mx: 1, bgcolor: '#fff', p: 2, borderRadius: '8px' }}>
          <Typography variant="subtitle1" sx={{ color: colors.teal, mb: 1 }}>Tenant Details</Typography>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={tenantData.name}
            onChange={handleChange}
            sx={{ mb: 2 }}
            required
          />
          <TextField
            fullWidth
            label="Phone"
            name="phone"
            value={tenantData.phone}
            onChange={handleChange}
            sx={{ mb: 2 }}
            required
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Property</InputLabel>
            <Select
              name="property"
              value={tenantData.property}
              onChange={handleChange}
              label="Property"
              required
              disabled
            >
              {properties.map((property) => (
                <MenuItem key={property._id} value={selectedProperty._id}>
                  {selectedProperty.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Room</InputLabel>
            <Select
              name="room"
              value={tenantData.room}
              onChange={handleChange}
              label="Room"
              required
              disabled
            >
              {rooms.map((room) => (
                <MenuItem key={room._id} value={room._id}>
                  {room.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Referred By"
            name="referredBy"
            value={tenantData.referredBy}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
        </Box>

        <Box sx={{ mb: 3, mx: 1, bgcolor: '#fff', p: 2, borderRadius: '8px' }}>
          <Typography variant="subtitle1" sx={{ color: '#1AA393', mb: 1 }}>Stay Details</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Move-in"
              value={tenantData.moveIn}
              onChange={(date) => handleDateChange(date, 'moveIn')}
              slotProps={{
                textField: {
                  fullWidth: true,
                  sx: { mb: 2 },
                  required: true,
                },
              }}
            />
            <DatePicker
              label="Move-out"
              value={tenantData.moveOut}
              onChange={(date) => handleDateChange(date, 'moveOut')}
              slotProps={{
                textField: {
                  fullWidth: true,
                  sx: { mb: 2 },
                },
              }}
            />
          </LocalizationProvider>
          <TextField
            fullWidth
            label="Lock-in Period"
            name="lockInPeriod"
            value={tenantData.lockInPeriod}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Notice Period"
            name="noticePeriod"
            value={tenantData.noticePeriod}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Auto Rent Addition</InputLabel>
            <Select
              name="autoRentAddition"
              value={tenantData.autoRentAddition}
              onChange={handleChange}
              label="Auto Rent Addition"
              required
            >
              <MenuItem value="1st of month">1st of month</MenuItem>
              <MenuItem value="15th of month">15th of month</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ mb: 3, mx: 1, bgcolor: '#fff', p: 2, borderRadius: '8px' }}>
          <Typography variant="subtitle1" sx={{ color: '#1AA393', mb: 1 }}>Payment Details</Typography>
          <TextField
            fullWidth
            label="Fixed Rent"
            name="fixedRent"
            value={tenantData.fixedRent}
            onChange={handleChange}
            sx={{ mb: 2 }}
            required
          />
          <TextField
            fullWidth
            label="Security Deposit"
            name="securityDeposit"
            value={tenantData.securityDeposit}
            onChange={handleChange}
            sx={{ mb: 2 }}
            required
          />
          <TextField
            fullWidth
            label="Rent Due"
            name="rentDue"
            value={tenantData.rentDue}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Security Deposit Due"
            name="securityDepositDue"
            value={tenantData.securityDepositDue}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
        </Box>

        <Button
          variant="contained"
          fontSize={'h3'}
          type="submit"
          sx={{ width: '90%' , color:'#fff',bgcolor:'#03A0A2',textTransform: 'none', borderRadius: 2 ,ml:2, p:1 ,fontSize:16 }}
        >
          Update
        </Button>
      </form>
      
    </Container>
  );
};

export default EditTenant;
