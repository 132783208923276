import React, { useState, useEffect, useContext,useMemo } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { BASE_URL } from '../../utils/IP'; 
import { Container, Box, Typography, Tabs, Tab, IconButton, Button, Paper, List, ListItem,
 ListItemText,Fab,TextField,InputAdornment, Alert, CircularProgress,ToggleButton,ToggleButtonGroup,Fade,Chip,
 Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,MenuItem,Menu,Avatar, Card, CardContent, Divider,Grid,Link,Collapse } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { deleteTenant,deleteDue,initiateExit,completeExit } from '../../features/propertySlice';
import EditIcon from '@mui/icons-material/Edit';
import colors from '../../styles/colors';
// Add these with your existing imports
import ReceiptIcon from '@mui/icons-material/Receipt';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import SearchIcon from '@mui/icons-material/Search';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import HomeIcon from '@mui/icons-material/Home';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import BoltIcon from '@mui/icons-material/Bolt';
import HandymanIcon from '@mui/icons-material/Handyman';
import SecurityIcon from '@mui/icons-material/Security';


const TenantProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tenantId } = useParams();
  const [error, setError] = useState(null);
  const [inviteSuccess, setInviteSuccess] = useState(false);
  const [expandedDueId, setExpandedDueId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { tenants, rooms, dues, loading,selectedProperty } = useSelector(state => state.property);
  const [filter, setFilter] = useState('pending');
  const [statusFilter, setStatusFilter] = useState('pending'); // Default to pending

  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [selectedDue, setSelectedDue] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');

  const handleInitiateExit = () => {
    setDialogType('initiateExit');
    setDialogMessage('Are you sure you want to initiate the exit process for this tenant?');
    setDialogOpen(true);
  };

  const handleCompleteExit = () => {
    setDialogType('completeExit');
    setDialogMessage('Are you sure you want to mark this tenant as exited?');
    setDialogOpen(true);
  };

  const handleDeleteTenant = () => {
    setDialogType('deleteTenant');
    setDialogMessage('Are you sure you want to delete this tenant?');
    setDialogOpen(true);
  };

  const handleDeleteDue = (due) => {
    setSelectedDue(due);
    setDialogType('deleteDue');
    setDialogMessage('Are you sure you want to delete this due? This action cannot be undone.');
    setDialogOpen(true);
  };

  const handleDialogConfirm = async () => {
    setDialogOpen(false);
    try {
      if (dialogType === 'deleteTenant') {
        await dispatch(deleteTenant(tenantId)).unwrap();
        setDialogMessage('Tenant deleted successfully!');
        setDialogType('success');
        setDialogOpen(true);
      } else if (dialogType === 'deleteDue' && selectedDue) {
        await dispatch(deleteDue(selectedDue._id)).unwrap();
        setDialogMessage('Due deleted successfully');
        setDialogType('success');
        setDialogOpen(true);
      } else if (dialogType === 'initiateExit') {
        await dispatch(initiateExit(tenant._id)).unwrap();
        setDialogMessage('Exit process initiated successfully');
        setDialogType('success');
        setDialogOpen(true);
      } else if (dialogType === 'completeExit') {
        await dispatch(completeExit(tenant._id)).unwrap();
        setDialogMessage('Tenant exit completed successfully');
        setDialogType('success');
        setDialogOpen(true);
      }
    } catch (error) {
      console.error(`Error ${dialogType}:`, error);
      setDialogMessage(`Failed to ${dialogType}. ${error.message}`);
      setDialogType('error');
      setDialogOpen(true);
    }
  };

  const handleCloseDialog = () => {
    if (dialogMessage === 'Tenant deleted successfully!' && dialogType === 'success') {
      setDialogOpen(false);
      setSelectedDue(null);
      navigate('/tenants');
    } else if (dialogMessage === 'Due deleted successfully' && dialogType === 'success') {
      setDialogOpen(false);
      setSelectedDue(null);
    } else if (dialogType === 'success' && (dialogMessage.includes('Exit process initiated') || dialogMessage.includes('Tenant exit completed'))) {
      setDialogOpen(false);
    } else {
      setDialogOpen(false);
      setSelectedDue(null);
    }
  };

  const handleOptionsClick = (event, due) => {
    event.stopPropagation(); // Prevent event bubbling
    setAnchorEl(event.currentTarget);
    setSelectedDue(due);
  };

  const handleOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateDue = () => {
    handleOptionsClose();
    navigate(`/update-due/${selectedDue._id}`, { state: { dueData: selectedDue } });
    setDialogMessage('Due updated successfully');
    setDialogType('success');
    setDialogOpen(true);
  };

  const [tabIndex, setTabIndex] = useState(() => {
    return location.state?.activeTab ?? 0;
  });

  const tenant = useMemo(() => tenants.find(t => t.tenant._id === tenantId), [tenants, tenantId]);
  const room = useMemo(() => tenants.find(r => r.room._id === tenant?.room._id), [tenants, tenant]);

 // Filter dues for this tenant only and based on status
// Filter dues for this tenant only
const tenantDues = useMemo(() => {
  return dues.filter(due => {
    // For debugging
    console.log("Checking due:", due);
    console.log("Due tenant:", due.tenant);
    console.log("Due tenant ID:", due.tenant?._id);
    
    // Check if the due belongs to this tenant
    return due.tenant?._id === tenantId;
  });
}, [dues, tenantId]);

  useEffect(() => {
    if (location.state?.activeTab !== undefined && location.state.activeTab !== tabIndex) {
      setTabIndex(location.state.activeTab);
    }
  }, [location.state, tabIndex]);

  const CATEGORIES = ['Rent', 'Water', 'Electricity', 'Maintenance', 'Security', 'Other'];

  const handleInviteTenant = async () => {
    setError(null);
    setInviteSuccess(false);

    if (!tenant) {
      setError('Tenant information not found. Cannot send invitation.');
      return;
    }
  
    const occupancyId = tenant._id;
  
    if (!occupancyId || !tenantId) {
      setError('Occupancy or Tenant information not found. Cannot send invitation.');
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${BASE_URL}/invite-tenant`, { // Remove the extra slash
        tenantId: tenantId,
        occupancyId: occupancyId,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      });
  
      const inviteToken = response.data.token;
      const inviteLink = `http://localhost:3002/tapp-signup?token=${inviteToken}`;
  
      console.log('Invitation Link:', inviteLink);
      setInviteSuccess(true);
    } catch (error) {
      console.error('Error sending invitation:', error);
      setError('Failed to send invitation. Please try again.');
    }
  };

  const handleBackClick = () => {
    
    navigate('/tenants');
  };

  const handleTabChange = (event, newValue) => {
    console.log('Tab changed to:', newValue);
    setTabIndex(newValue);
    navigate('.', { 
      state: { ...location.state, activeTab: newValue, previousTab: location.state?.previousTab }, 
      replace: true 
    });
  };

  const handleRecordPayment = (dueId) => {
    navigate(`/record-payment2/${dueId}`);
  };

  // Toggle to switch between the paid dues & pending dues

  const handleFilterChange = (_, newFilter) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };
  
    // Filter dues based on status and search term
    const filteredDues = useMemo(() => {
      return tenantDues.filter(due => {
        switch(filter) {
          case 'paid':
            return due.status === 'Paid';
          case 'verifying':
            return due.status === 'UnderVerification';
          case 'pending':
          default:
            return due.status === 'Pending';
        }
      }).sort((a, b) => new Date(b.dueDate) - new Date(a.dueDate));
    }, [tenantDues, filter]);

   // Modify the icon helper function
  const getDueIcon = (dueType) => {
    switch (dueType?.toLowerCase()) {
      case 'rent':
        return <HomeIcon sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />;
      case 'water':
        return <WaterDropIcon sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />;
      case 'electricity':
        return <BoltIcon sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />;
      case 'maintenance':
        return <HandymanIcon sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />;
      case 'security':
        return <SecurityIcon sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />;
      default:
        return <ReceiptIcon sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />;
    }
  };

  const getStatusColors = (status) => {
    switch(status) {
      case 'Paid':
        return { bg: '#E5F4F3', color: '#1AA393' };
      case 'UnderVerification':
        return { bg: '#FFF1D0', color: '#FF9800' };
      case 'Pending':
      default:
        return { bg: '#FFE5E5', color: '#FF4B4B' };
    }
  };
  
  const getStatusBgColor = (status) => {
    switch (status) {
      case 'Paid':
        return `${colors.teal}15`;
      case 'UnderVerification':
        return '#FFE5E5';
      default:
        return '#FFF1D0';
    }
  };
  
  const getAmountColor = (status) => {
    switch (status) {
      case 'Paid':
        return colors.teal;
      case 'UnderVerification':
        return '#FF4B4B';
      default:
        return '#FF4B4B';
    }
  };
  
  const getStatusLabel = (status) => {
    switch (status) {
      case 'UnderVerification':
        return 'Under Review';
      default:
        return status;
    }
  };

  const totalPendingAmount = useMemo(() => 
    dues.filter(d => d.status === 'Pending')
      .reduce((sum, due) => sum + due.dueAmount, 0), 
    [dues]
  ); 


  const handleDueClick = (dueId) => {
    setExpandedDueId(expandedDueId === dueId ? null : dueId);
  };


  const renderTabContent = () => {

    console.log('tenantData in rendering:', tenant);

      if (loading.tenants || loading.rooms || loading.dues) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </Box>
      );
    }

    if (!tenant) {
      return <Typography variant="h6">No tenant data available</Typography>;
    }


    switch (tabIndex) {
      case 0:
        return (
          <Box sx={{bgcolor:'#FFF'}}>
            <Box sx={{ p: 2, ml: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Profile Information</Typography>
              <Grid container spacing={2} sx={{ mt: 1, wordBreak: 'break-word' }}>
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Name</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1">{tenant.tenant.name}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Room Number</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1">{tenant?.room.name || 'N/A'}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Contact Number</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1">{tenant.tenant.phone}</Typography>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ p: 2, ml: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Stay Details</Typography>
              <Grid container spacing={2} sx={{ mt: 1, wordBreak: 'break-word' }}>
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Move-in Date</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1">{new Date(tenant.moveIn).toLocaleDateString()}</Typography>
                </Grid>
                {tenant.moveOut && (
                  <>
                    <Grid item xs={5}>
                      <Typography variant="body2" color="textSecondary">Move-out Date</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="body1">{new Date(tenant.moveOut).toLocaleDateString()}</Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
            <Box sx={{ p: 2, ml: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Rent Details</Typography>
              <Grid container spacing={2} sx={{ mt: 1, wordBreak: 'break-word' }}>
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Fixed Rent</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1">{`₹${tenant.fixedRent}`}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Security Deposit</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1">{`₹${tenant.securityDeposit}`} </Typography>
                </Grid>
              </Grid>
              <Link onClick={() => navigate(`/edit-tenant/${tenantId}`)} underline="hover" sx={{ display: 'flex', alignItems: 'center', mt: 2, color: '#03A0A2', textDecoration: 'underline' }}>
                <EditIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />
                <Typography variant="body2">Edit details</Typography>
              </Link>
            </Box>
              <Box sx={{ p: 2, ml: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Occupancy Status</Typography>
              <Grid container spacing={2} sx={{ mt: 1, wordBreak: 'break-word' }}>
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Status</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1">{tenant.status}</Typography>
                </Grid>
                {tenant.noticeDate && (
                  <>
                    <Grid item xs={5}>
                      <Typography variant="body2" color="textSecondary">Notice Date</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="body1">{new Date(tenant.noticeDate).toLocaleDateString()}</Typography>
                    </Grid>
                  </>
                )}
                {tenant.exitDate && (
                  <>
                    <Grid item xs={5}>
                      <Typography variant="body2" color="textSecondary">Exit Date</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="body1">{new Date(tenant.exitDate).toLocaleDateString()}</Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
            <Box sx={{ mx: 3, display: 'flex', justifyContent: 'space-between', mt: 2, mb: 8 }}>
                <Button variant="contained" onClick={handleInviteTenant} 
                  sx={{ color:'#fff', bgcolor:'#1AA393', fontSize: 10, boxShadow: 0, borderRadius: 4 }}>
                  Invite Tenant
                </Button>
              {tenant.status === 'Active' && (
                <Button variant="contained" onClick={handleInitiateExit}
                  sx={{ color: '#fff', bgcolor: '#DA6565', fontSize: 10, boxShadow: 0, borderRadius: 4 }}>
                  Initiate Exit
                </Button>
              )}
              {tenant.status === 'OnNotice' && (
                <Button variant="contained" onClick={handleCompleteExit}
                  sx={{ color: '#fff', bgcolor: '#DA6565', fontSize: 10, boxShadow: 0, borderRadius: 4 }}>
                  Complete Exit
                </Button>
              )}
            </Box>
        </Box>
          
        );
        case 1:


          return (<Box sx={{ pb: 8, width: '100%', maxWidth: '100vw' }}>
            {/* Tab Design - Full Width + Status Colors */}
            <Box sx={{ 
              display: 'flex',
              width: '100%',
              borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            }}>
              {[
                { label: 'Pending', color: '#FF4B4B', bgColor: '#FFE5E5' },
                { label: 'Verifying', color: '#FFB800', bgColor: '#FFF1D0' },
                { label: 'Paid', color: '#1AA393', bgColor: '#E5F4F3' }
              ].map((tab) => (
                <Box
                  key={tab.label}
                  onClick={() => setFilter(tab.label.toLowerCase())}
                  sx={{
                    flex: 1,
                    p: 1.5,
                    textAlign: 'center',
                    cursor: 'pointer',
                    bgcolor: filter === tab.label.toLowerCase() ? tab.bgColor : 'transparent',
                    color: filter === tab.label.toLowerCase() ? tab.color : 'rgba(0, 0, 0, 0.6)',
                    transition: 'all 0.2s ease'
                  }}
                >
                  <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
                    {tab.label}
                  </Typography>
                </Box>
              ))}
            </Box>
      
            {/* Modern Category Filter */}
            <Box sx={{ px: 2, py: 2 }}>
              <Box sx={{ 
                display: 'flex',
                gap: 1,
                overflowX: 'auto',
                WebkitOverflowScrolling: 'touch',
                scrollSnapType: 'x mandatory',
                '&::-webkit-scrollbar': { display: 'none' },
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
              }}>
                {['All', ...CATEGORIES].map((category) => (
                  <Chip
                    key={category}
                    label={category}
                    onClick={() => setSelectedCategory(category.toLowerCase())}
                    sx={{
                      px: 2,
                      height: 32,
                      borderRadius: '16px',
                      bgcolor: selectedCategory === category.toLowerCase() ? '#1AA393' : 'rgba(0, 0, 0, 0.08)',
                      color: selectedCategory === category.toLowerCase() ? 'white' : 'rgba(0, 0, 0, 0.87)',
                      '&:hover': {
                        bgcolor: selectedCategory === category.toLowerCase() ? '#158678' : 'rgba(0, 0, 0, 0.12)',
                      },
                      cursor: 'pointer',
                      whiteSpace: 'nowrap'
                    }}
                  />
                ))}
              </Box>
            </Box>
      
            {/* Dues List - Full Width Cards */}
            {filteredDues
              .filter(due => selectedCategory === 'all' || due.dueType.toLowerCase() === selectedCategory)
              .map((due) => {
                const statusColors = getStatusColors(due.status);
                
                return (
                  <Card
                    key={due._id}
                    onClick={() => setExpandedDueId(expandedDueId === due._id ? null : due._id)}
                    sx={{
                      mx: 1,
                      mb: 2,
                      boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                      borderRadius: 3,
                      cursor: 'pointer',
                      width: 'calc(100% - 16px)', // Full width minus margins
                    }}
                  >
                    <Box sx={{ p: 2 }}>
      
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {/* Icon with dynamic background color */}
                    <Box sx={{
                      width: 40,
                      height: 40,
                      borderRadius: '50%',
                      bgcolor: statusColors.bg,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      {getDueIcon(due.dueType)}
                    </Box>

                    {/* Due Type and Amount */}
                    <Box>
                      <Typography 
                      variant="subtitle1"
                      sx={{ 
                        fontWeight: 600, color: colors.primary,
                      
                      }}>
                        {due.dueType}
                      </Typography>
                      <Typography
                      variant='h6' sx={{ 
                    //fontSize: '1.5rem', // Larger font size for amount
                    fontWeight: 'bold',
                    color: statusColors.color,
                    //fontFamily: 'Roboto' // Consistent font family
                  }}>
                    ₹{due.status === 'Paid' ? 
                        due.paymentAmount?.toLocaleString() : 
                        due.dueAmount?.toLocaleString()}
                  </Typography>
                </Box>
                
                  </Box>

                  {/* Status and Arrow */}
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {due.status === 'Paid' ? (
                      <Typography variant="caption" sx={{ color: statusColors.color }}>
                        {due.paymentMode}
                      </Typography>
                    ) : (
                      <Chip
                        label={due.status === 'UnderVerification' ? 'Under Review' : due.status}
                        size="small"
                        sx={{
                          bgcolor: statusColors.bg,
                          color: statusColors.color,
                          height: 24,
                          fontSize: '0.75rem'
                        }}
                      />
                    )}
                    <KeyboardArrowDownIcon />
                  </Box>
                </Box>

                <Typography variant="caption" color="text.secondary">
                  {due.status === 'Paid' ? 
                    `Paid on ${new Date(due.paymentDateTime).toLocaleDateString(undefined,{year:'numeric',month:'short',day:'numeric'})}` :
                    `Due on ${new Date(due.dueDate).toLocaleDateString(undefined,{year:'numeric',month:'short',day:'numeric'})}`}
                </Typography>
                

                {/* Expandable Section - Different for each status */}
                <Collapse in={expandedDueId === due._id}>
                  <Box sx={{
                    mt: 2,
                    pt: 2,
                    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    gap: 2
                  }}>
                    {due.status === 'Pending' && (
                      <>
                        <Button
                          variant="contained"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRecordPayment(due._id);
                          }}
                          sx={{
                            flex: 1,
                            bgcolor: '#1AA393',
                            '&:hover': { bgcolor: '#158678' },
                            textTransform: 'none'
                          }}
                        >
                          Record Payment
                        </Button>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOptionsClick(e, due);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </>
                    )}

                    {due.status === 'Paid' && (
                      <>
                        <Button
                          variant="outlined"
                          onClick={(e) => {
                            e.stopPropagation();
                            due.receipt && window.open(`${BASE_URL}${due.receipt.pdfUrl}`, '_blank');
                          }}
                          sx={{
                            flex: 1,
                            borderColor: '#1AA393',
                            color: '#1AA393',
                            textTransform: 'none'
                          }}
                        >
                          View Receipt
                        </Button>
                        {due.paymentScreenshot && (
                          <Button
                            variant="outlined"
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(`${BASE_URL}${due.paymentScreenshot}`, '_blank');
                            }}
                            sx={{
                              flex: 1,
                              borderColor: '#1AA393',
                              color: '#1AA393',
                              textTransform: 'none'
                            }}
                          >
                            View Screenshot
                          </Button>
                        )}
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            if (due.receipt) {
                              const message = `Payment receipt for ${due.dueType}: ${BASE_URL}${due.receipt.pdfUrl}`;
                              window.open(`https://wa.me/?text=${encodeURIComponent(message)}`, '_blank');
                            }
                          }}
                        >
                          <WhatsAppIcon />
                        </IconButton>
                      </>
                    )}

                    {due.status === 'UnderVerification' && (
                      <Typography variant="body2" color="text.secondary">
                        Payment verification in progress...
                      </Typography>
                    )}
                  </Box>
                </Collapse>
              </Box>
            </Card>
          );
        })}
    </Box>
          );
        default:
          return null;
      }
  };

  return (
    <Container sx={{bgcolor: colors.secondary , pb:6, width:'100%'}}>
       {console.log('Rendering with tab index:', tabIndex)}
      <Box
        sx={{
          bgcolor: colors.secondary, // Primary color
          pb: 2,
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
          mx: -2,
          px: 2,
          color: colors.primary,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', pt: 2 }}>
          <IconButton onClick={handleBackClick} sx={{ color: colors.primary }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ fontSize: '1.2rem', textAlign: 'left', flexGrow: 1 }}>
            {tenant ? tenant.tenant.name : 'Tenant Profile'}
          </Typography>
          {tenant && (
            <Typography variant="h6" sx={{ fontSize: '1.2rem', textAlign: 'right' }}>
              {tenant.room.name}
            </Typography>
          )}
        </Box>
      </Box>

      <Tabs value={tabIndex} onChange={handleTabChange} centered>
        <Tab label="Tenant Details" />
        <Tab label="Passbook" />
      </Tabs>

      {renderTabContent()}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleOptionsClose}
      >
        <MenuItem onClick={handleUpdateDue}>
          Update
        </MenuItem>
        <MenuItem onClick={() => {
          handleOptionsClose();
          handleDeleteDue(selectedDue);
        }}>
          Delete
        </MenuItem>
      </Menu>

      {error && (
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      
      {inviteSuccess && (
        <Alert severity="success" onClose={() => setInviteSuccess(false)}>
          Invitation sent successfully!
        </Alert>
      )}

<Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogType === 'deleteTenant' || dialogType === 'deleteDue' ? "Confirm Action" : 
           dialogType === 'success' ? "Success" : 
           dialogType === 'error' ? "Error" :
           dialogType === 'initiateExit' ? "Initiate Exit Process" :
           dialogType === 'completeExit' ? "Complete Exit Process" : "Confirm"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {(dialogType === 'deleteTenant' || dialogType === 'deleteDue' || dialogType === 'initiateExit' || dialogType === 'completeExit') ? (
            <>
              <Button onClick={handleCloseDialog}>Cancel</Button>
              <Button onClick={handleDialogConfirm} autoFocus>
                Confirm
              </Button>
            </>
          ) : (
            <Button onClick={handleCloseDialog} autoFocus>
              OK
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default TenantProfile;