import React from 'react';
import { Container, Box, Typography, TextField, Button } from '@mui/material';

const Signup = () => {
  return (
    <Container>
      <Box sx={{ mt: 8, textAlign: 'center' }}>
        <Typography variant="h4" sx={{ mb: 2 }}>Signup</Typography>
        <TextField label="Name" variant="outlined" fullWidth sx={{ mb: 2 }} />
        <TextField label="Email" variant="outlined" fullWidth sx={{ mb: 2 }} />
        <TextField label="Password" type="password" variant="outlined" fullWidth sx={{ mb: 2 }} />
        <Button variant="contained" color="primary" sx={{ backgroundColor: '#90AFC5', textTransform: 'none', mt: 2 }}>
          Signup
        </Button>
        <Typography variant="body2" sx={{ mt: 2 }}>
          Already have an account? <a href="/login">Login</a>
        </Typography>
      </Box>
    </Container>
  );
};

export default Signup;
