import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@mui/material';

const CentralDialog = ({ 
  open, 
  onClose, 
  title, 
  content, 
  type,
  onConfirm,
  confirmText = 'Confirm',
  cancelText = 'Cancel'
}) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {type === 'confirm' && (
          <>
            <Button onClick={onClose} color="primary">
              {cancelText}
            </Button>
            <Button onClick={handleConfirm} color="primary" autoFocus>
              {confirmText}
            </Button>
          </>
        )}
        {(type === 'success' || type === 'error') && (
          <Button onClick={onClose} color="primary">
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CentralDialog;