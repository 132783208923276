import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, TextField, Button, Typography, Box, Paper } from '@mui/material';
import { login } from '../../features/authSlice';
import { fetchProperties } from '../../features/propertySlice';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import colors from '../../styles/colors';


// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: colors.teal,
    },
  },
});

const Login = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const resultAction = await dispatch(login(credentials)).unwrap();
      console.log('Login successful:', resultAction);
      await dispatch(fetchProperties(resultAction.id));
      navigate('/home');
    } catch (error) {
      console.error('Login - Error logging in:', error);
      if (error.response && error.response.data) {
        setError(error.response.data.message || 'An error occurred during login.');
      } else {
        setError('Network error. Please ensure the backend server is running and accessible.');
      }
    }
  };

  const textFieldSx = {
    '& .MuiInputBase-root': {
      color: colors.primary,
    },
    '& .MuiInputLabel-root': { 
      color: colors.primary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': { borderColor: colors.primary },
      '&:hover fieldset': { borderColor: colors.primary },
    },
    '& .MuiInputBase-input': {
      '&::selection': {
        backgroundColor: `${colors.primary}40`, // 40 here represents 25% opacity
      },
    },
  };


  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: '100vh',
          width: '100vw',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          background: 'rgba(255, 255, 255, 0.1)',
        }}
      >
        <Box 
          sx={{
            ml:0.8,mr:0.8,
            padding: 4,
            marginTop: '15vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: { xs: '90%', sm: '400px' },
            maxWidth: '400px',
            backdropFilter: 'blur(20px)',
            borderRadius: '8px',
            boxShadow: '0 2px 50px rgba(0, 0, 0, 0.15)',
            border: `1px solid ${colors.secondary}40`,
          }}
        >
        <Box
             component="img"
              sx={{  width: '150px', marginBottom: '20px' }}
              alt="Company Logo"
              src={`${process.env.PUBLIC_URL}/icons/Rizzy_black_logo0.33x.png`}
                    />
         
          <Box component="form" onSubmit={handleLogin} sx={{ mt: 1, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={credentials.username}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={credentials.password}
              onChange={handleChange}
            />
            {error && (
              <Typography color="error" sx={{ mt: 1 }}>
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 ,fontWeight:600}}
            >
              Sign In
              </Button>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Login;